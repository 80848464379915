var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-sidebar", {
    attrs: {
      id: "add-new-user-sidebar",
      visible: _vm.isDuplicateSingleShiftSidebarActive,
      backdrop: "",
      "bg-variant": "white",
      "no-header": "",
      right: "",
      shadow: "",
      "sidebar-class": "sidebar-lg"
    },
    on: {
      change: function(val) {
        return _vm.$emit("update:is-duplicate-single-shift-sidebar-active", val)
      }
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var hide = ref.hide
          return [
            _c("b-overlay", { attrs: { show: _vm.loading, rounded: "sm" } }, [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
                },
                [
                  _c("h5", { staticClass: "mb-0" }, [
                    _vm._v(" Duplicate Single Shift ")
                  ]),
                  _c("feather-icon", {
                    staticClass: "ml-1 cursor-pointer",
                    attrs: { icon: "XIcon", size: "16" },
                    on: { click: hide }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "p-2" },
                [
                  _c(
                    "validation-observer",
                    { ref: "duplicateShiftForm" },
                    [
                      _c(
                        "b-row",
                        [
                          _c("b-col", { attrs: { md: "12" } }, [
                            _c("p", [
                              _vm._v(
                                "shift will be duplicated as drafts. To make them visible to staff, you will need to publish rota. "
                              )
                            ])
                          ])
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        [
                          _c("b-col", { attrs: { md: "12" } }, [
                            _c(
                              "p",
                              {
                                staticClass: "font-weight-bold font-weight-700"
                              },
                              [_vm._v("Selected Shift:")]
                            )
                          ])
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { attrs: { cols: "12", md: "12" } },
                            [
                              _c("label", [_vm._v("Date")]),
                              _c(
                                "b-form-group",
                                [
                                  _c("flat-pickr", {
                                    staticClass: "form-control",
                                    attrs: {
                                      config: {
                                        enableTime: false,
                                        noCalendar: false,
                                        dateFormat: "d/m/y"
                                      },
                                      disabled: "",
                                      placeholder: "Select date"
                                    },
                                    model: {
                                      value: _vm.copy_date,
                                      callback: function($$v) {
                                        _vm.copy_date = $$v
                                      },
                                      expression: "copy_date"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { cols: "12", md: "6" } },
                            [
                              _c("label", [_vm._v("Start Time")]),
                              _c(
                                "b-form-group",
                                [
                                  _c("flat-pickr", {
                                    staticClass: "form-control",
                                    attrs: {
                                      config: {
                                        enableTime: true,
                                        noCalendar: true,
                                        dateFormat: "H:i:ss"
                                      },
                                      disabled: ""
                                    },
                                    model: {
                                      value: _vm.copy_to_start,
                                      callback: function($$v) {
                                        _vm.copy_to_start = $$v
                                      },
                                      expression: "copy_to_start"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { cols: "12", md: "6" } },
                            [
                              _c("label", [_vm._v("End Time ")]),
                              _c(
                                "b-form-group",
                                [
                                  _c("flat-pickr", {
                                    staticClass: "form-control",
                                    attrs: {
                                      config: {
                                        enableTime: true,
                                        noCalendar: true,
                                        dateFormat: "H:i:ss"
                                      },
                                      disabled: ""
                                    },
                                    model: {
                                      value: _vm.copy_to_end,
                                      callback: function($$v) {
                                        _vm.copy_to_end = $$v
                                      },
                                      expression: "copy_to_end"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        [
                          _c("b-col", { attrs: { md: "12" } }, [
                            _c(
                              "p",
                              {
                                staticClass: "font-weight-bold font-weight-700"
                              },
                              [_vm._v("Paste to:")]
                            )
                          ])
                        ],
                        1
                      ),
                      _c("b-row"),
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { attrs: { cols: "12", md: "12" } },
                            [
                              _c("label", [_vm._v("Date")]),
                              _c(
                                "b-form-group",
                                [
                                  _c("validation-provider", {
                                    attrs: {
                                      name: "End Time",
                                      rules: "required"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(ref) {
                                            var errors = ref.errors
                                            return [
                                              _c("flat-pickr", {
                                                staticClass: "form-control",
                                                attrs: {
                                                  config: {
                                                    enableTime: false,
                                                    noCalendar: false,
                                                    minDate: new Date(),
                                                    dateFormat: "d/m/y",
                                                    mode: "multiple",
                                                    locale: {
                                                      firstDayOfWeek: 1
                                                    }
                                                  },
                                                  placeholder:
                                                    "Select shift paste  date"
                                                },
                                                model: {
                                                  value: _vm.paste_to_day,
                                                  callback: function($$v) {
                                                    _vm.paste_to_day = $$v
                                                  },
                                                  expression: "paste_to_day"
                                                }
                                              }),
                                              errors[0]
                                                ? _c(
                                                    "small",
                                                    {
                                                      staticClass: "text-danger"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Select shift paste data"
                                                      )
                                                    ]
                                                  )
                                                : _vm._e()
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { staticClass: "mb-1", attrs: { cols: "12" } },
                            [
                              _c(
                                "b-form-checkbox",
                                {
                                  attrs: { value: "true" },
                                  model: {
                                    value: _vm.isVacant,
                                    callback: function($$v) {
                                      _vm.isVacant = $$v
                                    },
                                    expression: "isVacant"
                                  }
                                },
                                [_vm._v(" Vacant Shift ")]
                              )
                            ],
                            1
                          ),
                          _vm.isVacant
                            ? _c(
                                "b-col",
                                { attrs: { cols: "12", md: "6" } },
                                [
                                  _c(
                                    "b-form-group",
                                    [
                                      _c("label", [_vm._v("Professions")]),
                                      _c("v-select", {
                                        attrs: {
                                          options: _vm.professionList,
                                          label: "text",
                                          placeholder: "Select Professions",
                                          multiple: "",
                                          reduce: function(professionList) {
                                            return professionList.id
                                          }
                                        },
                                        model: {
                                          value: _vm.selectedVacantRole,
                                          callback: function($$v) {
                                            _vm.selectedVacantRole = $$v
                                          },
                                          expression: "selectedVacantRole"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          !_vm.isVacant
                            ? _c(
                                "b-col",
                                { attrs: { cols: "12", md: "6" } },
                                [
                                  _c(
                                    "b-form-group",
                                    [
                                      _c("label", [_vm._v("Professions")]),
                                      _c("v-select", {
                                        attrs: {
                                          options: _vm.groups.filter(function(
                                            m
                                          ) {
                                            return m.id !== -1
                                          }),
                                          label: "text",
                                          placeholder: "Select Role"
                                        },
                                        model: {
                                          value: _vm.selectedProfessionsForEdit,
                                          callback: function($$v) {
                                            _vm.selectedProfessionsForEdit = $$v
                                          },
                                          expression:
                                            "selectedProfessionsForEdit"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.isVacant
                            ? _c(
                                "b-col",
                                { attrs: { cols: "12", md: "6" } },
                                [
                                  _c(
                                    "b-form-group",
                                    [
                                      _c("label", [_vm._v("Role (Optional)")]),
                                      _c("v-select", {
                                        attrs: {
                                          options: _vm.professionsList,
                                          label: "text",
                                          placeholder: "Select Role",
                                          multiple: "",
                                          reduce: function(professionsList) {
                                            return professionsList.value
                                          }
                                        },
                                        model: {
                                          value: _vm.selectedRoleForEdit,
                                          callback: function($$v) {
                                            _vm.selectedRoleForEdit = $$v
                                          },
                                          expression: "selectedRoleForEdit"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          !_vm.isVacant
                            ? _c(
                                "b-col",
                                { attrs: { cols: "12", md: "6" } },
                                [
                                  _c(
                                    "b-form-group",
                                    [
                                      _c("label", [
                                        _vm._v("Healthcare Professional")
                                      ]),
                                      _c("validation-provider", {
                                        attrs: {
                                          name: "End Time",
                                          rules: "required"
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var errors = ref.errors
                                                return [
                                                  _c("v-select", {
                                                    attrs: {
                                                      options: _vm.users.filter(
                                                        function(x) {
                                                          return (
                                                            x.groupId ===
                                                            _vm
                                                              .selectedProfessionsForEdit
                                                              .val
                                                          )
                                                        }
                                                      ),
                                                      label: "text",
                                                      placeholder:
                                                        "Select healthcare professional"
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.selectedHealthcare_professionalForEdit,
                                                      callback: function($$v) {
                                                        _vm.selectedHealthcare_professionalForEdit = $$v
                                                      },
                                                      expression:
                                                        "selectedHealthcare_professionalForEdit"
                                                    }
                                                  }),
                                                  errors[0]
                                                    ? _c(
                                                        "small",
                                                        {
                                                          staticClass:
                                                            "text-danger"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Select healthcare professional"
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e()
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "flex mt-5" },
                        [
                          _c(
                            "b-button",
                            {
                              directives: [
                                {
                                  name: "ripple",
                                  rawName: "v-ripple.400",
                                  value: "rgba(255, 255, 255, 0.15)",
                                  expression: "'rgba(255, 255, 255, 0.15)'",
                                  modifiers: { "400": true }
                                }
                              ],
                              attrs: { variant: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.duplicateFormSubmit()
                                }
                              }
                            },
                            [_vm._v(" Duplicate ")]
                          ),
                          _c(
                            "b-button",
                            {
                              directives: [
                                {
                                  name: "ripple",
                                  rawName: "v-ripple.400",
                                  value: "rgba(186, 191, 199, 0.15)",
                                  expression: "'rgba(186, 191, 199, 0.15)'",
                                  modifiers: { "400": true }
                                }
                              ],
                              staticClass: "text-primary ml-1 shadow",
                              attrs: { variant: "outline-white" },
                              on: {
                                click: function($event) {
                                  _vm.isDuplicateSingleShiftSidebarActive = false
                                }
                              }
                            },
                            [_vm._v(" Cancel ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ])
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }