var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-row",
        [
          _c("b-col", { attrs: { cols: "12", md: "12" } }, [
            _c(
              "div",
              { staticClass: "flex float-right" },
              [
                _c(
                  "b-button",
                  {
                    directives: [
                      {
                        name: "b-tooltip",
                        rawName: "v-b-tooltip.hover.top",
                        value: "Duplicate week",
                        expression: "'Duplicate week'",
                        modifiers: { hover: true, top: true }
                      },
                      {
                        name: "ripple",
                        rawName: "v-ripple.400",
                        value: "rgba(113, 102, 240, 0.15)",
                        expression: "'rgba(113, 102, 240, 0.15)'",
                        modifiers: { "400": true }
                      }
                    ],
                    staticClass: "btn-icon mr-1 text-primary shadow-lg",
                    attrs: {
                      disabled: _vm.eventSettings.dataSource.length === 0,
                      variant: "lighten-2",
                      size: "sm"
                    },
                    on: {
                      click: function($event) {
                        return _vm.duplicateShifts()
                      }
                    }
                  },
                  [
                    _c("feather-icon", {
                      attrs: { size: "20", icon: "CopyIcon" }
                    })
                  ],
                  1
                ),
                _vm.draftLength === 0
                  ? _c(
                      "b-button",
                      {
                        directives: [
                          {
                            name: "ripple",
                            rawName: "v-ripple.400",
                            value: "rgba(255, 255, 255, 0.15)",
                            expression: "'rgba(255, 255, 255, 0.15)'",
                            modifiers: { "400": true }
                          }
                        ],
                        attrs: { disabled: "", variant: "dark" },
                        on: {
                          click: function($event) {
                            return _vm.publishScheduler()
                          }
                        }
                      },
                      [_c("span", [_vm._v(" All Shifts Published")])]
                    )
                  : _vm._e(),
                _vm.draftLength !== 0
                  ? _c(
                      "b-button",
                      {
                        directives: [
                          {
                            name: "ripple",
                            rawName: "v-ripple.400",
                            value: "rgba(255, 255, 255, 0.15)",
                            expression: "'rgba(255, 255, 255, 0.15)'",
                            modifiers: { "400": true }
                          }
                        ],
                        attrs: {
                          variant: _vm.draftLength !== 0 ? "dark" : "light-dark"
                        },
                        on: {
                          click: function($event) {
                            return _vm.publishScheduler()
                          }
                        }
                      },
                      [
                        _vm.draftLength === 1
                          ? _c("span", [
                              _vm._v(
                                "Publish " + _vm._s(_vm.draftLength) + " Shift"
                              )
                            ])
                          : _vm._e(),
                        _vm.draftLength > 1
                          ? _c("span", [
                              _vm._v(
                                "Publish " + _vm._s(_vm.draftLength) + " Shift"
                              )
                            ])
                          : _vm._e()
                      ]
                    )
                  : _vm._e()
              ],
              1
            )
          ])
        ],
        1
      ),
      _c(
        "b-card",
        { staticClass: "mt-2 full-height", attrs: { "bg-variant": "" } },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "12", md: "2" } },
                [
                  _c("v-select", {
                    attrs: {
                      options: _vm.JObRoleFilterOption,
                      reduce: function(JObRoleFilterOption) {
                        return JObRoleFilterOption.id
                      },
                      label: "text",
                      placeholder: "Select Profession",
                      value: "id"
                    },
                    model: {
                      value: _vm.filters["job_role_id"],
                      callback: function($$v) {
                        _vm.$set(_vm.filters, "job_role_id", $$v)
                      },
                      expression: "filters['job_role_id']"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "12", md: "2" } },
                [
                  _c("v-select", {
                    staticClass: "mb-1",
                    attrs: {
                      dir: _vm.$store.state.appConfig.isRTL ? "rtl" : "ltr",
                      options: _vm.roleOptions,
                      reduce: function(roleOptions) {
                        return roleOptions.id
                      },
                      label: "name",
                      placeholder: "Select Role"
                    },
                    model: {
                      value: _vm.selected_role_ids,
                      callback: function($$v) {
                        _vm.selected_role_ids = $$v
                      },
                      expression: "selected_role_ids"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "12", md: "2" } },
                [
                  _c("v-select", {
                    staticClass: "mb-1",
                    attrs: {
                      dir: _vm.$store.state.appConfig.isRTL ? "rtl" : "ltr",
                      options: [
                        { title: "All", value: "all" },
                        { title: "Vacant", value: "vacant" },
                        { title: "Filled", value: "filled" },
                        { title: "Draft", value: "draft" },
                        { title: "Cancelled", value: "cancelled" }
                      ],
                      label: "title",
                      placeholder: "Select Shift Type"
                    },
                    model: {
                      value: _vm.selectedShiftType,
                      callback: function($$v) {
                        _vm.selectedShiftType = $$v
                      },
                      expression: "selectedShiftType"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "12", md: "2" } },
                [
                  _c("v-select", {
                    attrs: {
                      options: _vm.save_employee.filter(function(m) {
                        return m.id !== -1
                      }),
                      reduce: function(save_employee) {
                        return save_employee.id
                      },
                      label: "text",
                      placeholder: "Select User",
                      value: "id",
                      multiple: ""
                    },
                    model: {
                      value: _vm.selectedUser,
                      callback: function($$v) {
                        _vm.selectedUser = $$v
                      },
                      expression: "selectedUser"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "12", md: "3" } },
                [
                  _c("v-select", {
                    staticClass: "mb-2",
                    attrs: {
                      dir: _vm.$store.state.appConfig.isRTL ? "rtl" : "ltr",
                      options: _vm.serviceOption,
                      reduce: function(serviceOption) {
                        return serviceOption.id
                      },
                      label: "name",
                      placeholder: "Select Service",
                      multiple: ""
                    },
                    model: {
                      value: _vm.selected_service_ids,
                      callback: function($$v) {
                        _vm.selected_service_ids = $$v
                      },
                      expression: "selected_service_ids"
                    }
                  })
                ],
                1
              ),
              _c("b-col", { attrs: { md: "2" } })
            ],
            1
          ),
          _c("b-overlay", { attrs: { show: _vm.show, rounded: "sm" } }, [
            _c("div", { staticClass: "schedule-vue-sample" }, [
              _c(
                "div",
                { staticClass: "control-section" },
                [
                  _c(
                    "ejs-schedule",
                    {
                      ref: "scheduler",
                      attrs: {
                        id: "scheduler",
                        cellDoubleClick: false,
                        "current-view": _vm.currentView,
                        dataBinding: _vm.dataBinding,
                        dateHeaderTemplate: _vm.dateHeaderTemplate,
                        eventClick: _vm.onEventClick,
                        eventDoubleClick: _vm.onEventClick,
                        eventRendered: _vm.onEventRendered,
                        eventSettings: _vm.eventSettings,
                        group: _vm.group,
                        popupOpen: _vm.openpopup,
                        quickInfoTemplates: _vm.quickInfoTemplates,
                        dragStart: _vm.dragStart,
                        dragStop: _vm.dragStop,
                        workDays: _vm.workDays,
                        "resource-header-template": _vm.resourceHeaderTemplate,
                        rowAutoHeight: true,
                        "show-header-bar": true,
                        timeScale: _vm.timeScale,
                        allowTextWrap: true,
                        textWrapSettings: _vm.wrapSettings,
                        cssClass: "quick-info-template"
                      }
                    },
                    [
                      _c(
                        "e-views",
                        [
                          _c("e-view", {
                            attrs: {
                              eventTemplate: _vm.weekTemplate,
                              cssClass: "e-outline",
                              displayName: "TODAY(S) SHIFTS",
                              option: "Agenda"
                            }
                          }),
                          _c("e-view", {
                            attrs: {
                              eventTemplate: _vm.weekTemplate,
                              displayName: "DAY",
                              option: "TimelineDay"
                            }
                          }),
                          _c("e-view", {
                            attrs: {
                              eventTemplate: _vm.weekTemplate,
                              displayName: "WEEK",
                              option: "TimelineWeek"
                            }
                          }),
                          _c("e-view", {
                            attrs: {
                              eventTemplate: _vm.monthTemplate,
                              displayName: "MONTH",
                              option: "TimelineMonth"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "e-resources",
                        [
                          _c("e-resource", {
                            attrs: {
                              "allow-multiple": _vm.allowMultiple,
                              "data-source": _vm.projectResourceDataSource,
                              allowTextWrap: true,
                              "color-field": "color",
                              field: "group_id",
                              "id-field": "id",
                              name: "Projects",
                              "text-field": "text",
                              title: "Choose Project",
                              id: "res",
                              expandedField: "IsExpand"
                            }
                          }),
                          _c("e-resource", {
                            attrs: {
                              "allow-multiple": _vm.allowMultiple,
                              "data-source": _vm.employeeDataSource,
                              "color-field": "color",
                              field: "user_id",
                              "group-i-d-field": "groupId",
                              "id-field": "id",
                              "image-field": "image",
                              name: "Employees",
                              "text-field": "text",
                              title: "Employee"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ])
          ])
        ],
        1
      ),
      _c("side-bar", {
        ref: "AddShiftSideBar",
        attrs: {
          "cell-data": _vm.selectedCell,
          "is-add-new-shift-sidebar-active": _vm.isAddNewShiftSidebarActive,
          "leave-data": _vm.leaveAndUnAvailabilities
        },
        on: {
          "update:isAddNewShiftSidebarActive": function($event) {
            _vm.isAddNewShiftSidebarActive = $event
          },
          "update:is-add-new-shift-sidebar-active": function($event) {
            _vm.isAddNewShiftSidebarActive = $event
          },
          createShift: function($event) {
            return _vm.createShiftcompleted()
          }
        }
      }),
      _c("user-create-shift-side-bar", {
        ref: "AddUserCreateShiftSideBar",
        attrs: {
          "cell-data": _vm.selectedCell,
          "is-add-new-user-create-shift-sidebar-active":
            _vm.isAddNewUserCreateShiftSidebarActive,
          "leave-data": _vm.leaveAndUnAvailabilities
        },
        on: {
          "update:isAddNewUserCreateShiftSidebarActive": function($event) {
            _vm.isAddNewUserCreateShiftSidebarActive = $event
          },
          "update:is-add-new-user-create-shift-sidebar-active": function(
            $event
          ) {
            _vm.isAddNewUserCreateShiftSidebarActive = $event
          },
          createShiftUser: function($event) {
            return _vm.createShiftCompletedInUser()
          }
        }
      }),
      _c("edit-side-bar", {
        ref: "EditShiftSideBar",
        attrs: {
          "cell-data": _vm.selectedCellEdit,
          "is-edit-shift-sidebar-active": _vm.isEditShiftSidebarActive
        },
        on: {
          "update:isEditShiftSidebarActive": function($event) {
            _vm.isEditShiftSidebarActive = $event
          },
          "update:is-edit-shift-sidebar-active": function($event) {
            _vm.isEditShiftSidebarActive = $event
          },
          editShift: function($event) {
            return _vm.editShift()
          }
        }
      }),
      _c("duplicate-side-bar", {
        ref: "DuplicateShiftSideBar",
        attrs: {
          "cell-data": _vm.selectedCell,
          "is-duplicate-shift-sidebar-active": _vm.isDuplicateShiftSidebarActive
        },
        on: {
          "update:isDuplicateShiftSidebarActive": function($event) {
            _vm.isDuplicateShiftSidebarActive = $event
          },
          "update:is-duplicate-shift-sidebar-active": function($event) {
            _vm.isDuplicateShiftSidebarActive = $event
          }
        }
      }),
      _c("duplicate-single-shift-side-bar", {
        ref: "DuplicateSingleShiftSideBar",
        attrs: {
          "cell-data": _vm.selectedCellView,
          groups: _vm.projectResourceDataSource,
          "is-duplicate-single-shift-sidebar-active":
            _vm.isDuplicateSingleShiftSidebarActive,
          users: _vm.employeeDataSource
        },
        on: {
          "update:isDuplicateSingleShiftSidebarActive": function($event) {
            _vm.isDuplicateSingleShiftSidebarActive = $event
          },
          "update:is-duplicate-single-shift-sidebar-active": function($event) {
            _vm.isDuplicateSingleShiftSidebarActive = $event
          },
          editShift: function($event) {
            return _vm.editShift()
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }