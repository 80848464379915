<template>
    <div>
      <div v-if="selectedCell && selectedCell.state !== 'cancel'" class="p-1">
        <div>
          <b-row class=""  v-if="selectedCell.role_id">
            <span :key="role_id.id" :index="role_id.id" v-for="role_id in selectedCell.role_id ">
            <h6 style="margin-right: 5px">{{role_id.label}},</h6>
            </span>
          </b-row>
          <b-row v-if="selectedCell.service">
            <b-badge size="sm" class="mr-1" v-if="selectedCell.is_covid_shift" variant="danger">
              COVID-19
            </b-badge>
            <b-badge size="sm" v-if="selectedCell.service.type ==='clinical'" :variant="'light-success'" class="text-capitalize">
              {{selectedCell.service.type}}
            </b-badge>
            <b-badge size="sm" v-if="selectedCell.service.type ==='non-clinical'" :variant="'light-info'" class="text-capitalize">
              {{selectedCell.service.type}}
            </b-badge>
          </b-row>
          <b-row class="mt-1" v-if="selectedCell">
            <feather-icon icon="CalendarIcon" class="mr-1" />
            {{momentFormat(selectedCell.StartTime,'ddd DD MMMM YYYY')}}
          </b-row>
          <b-row class="mt-1" v-if="selectedCell">
            <feather-icon icon="ClockIcon" class="mr-1"  />
            {{momentFormat(selectedCell.StartTime,'HH:mm')}} - {{momentFormat(selectedCell.EndTime,'HH:mm')}}
          </b-row>
          <b-row class="mt-1" v-if="selectedCell.break_minutes">
            <feather-icon icon="ClockIcon" class="mr-1"  />
            ({{selectedCell.break_minutes}} m) 
          </b-row>
          <b-row class="mt-1">
            <feather-icon icon="MapPinIcon" class="mr-1"  />
           {{selectedCell.practice_name}}
          </b-row>
          <b-row class="mt-1" v-if="selectedCell.user">
            <feather-icon icon="UsersIcon" class="mr-1"  />
            {{selectedCell.user.first_name +' ' +selectedCell.user.last_name}}
          </b-row>
          <b-row class="mt-1 applications" v-if="selectedCell.State ==='pending'" @click="viewApplications()"  >
            <spn class="applicationIcon">
              <feather-icon icon="UsersIcon" />
              <span class="">2</span>
            </spn>
            <span class="applicationText">View Applications</span>
          </b-row>
          <b-row class="mt-1"  v-if="!selectedCell.user">
            <feather-icon icon="UsersIcon" class="mr-1"  />
            <span :key="jobRole.id" :index="jobRole.id" v-for="jobRole in selectedCell.vacant_job_roles ">
            <span style="margin-right: 5px">{{jobRole.job_role.name}},</span>
            </span>
          </b-row>
          <b-row class="mt-1" v-if="selectedCell.shift_type">
           <h6>{{selectedCell.shift_type.name}}</h6>
          </b-row>
          <b-row class="mt-1 applications" v-if="selectedCell.applications_count !== 0"  >
            <span class="applicationIcon" style="margin-bottom:2px; background-color: black; padding:2px; width: 35px;border-radius: 8px">
              <feather-icon icon="UsersIcon" class="userIcon"/>
              <span class="" style="padding-left: 5px;">{{selectedCell.applications_count}}</span>
            </span>
            <span class="applicationText" @click="editEvent()">View Applications</span>
          </b-row>
        </div>
      </div>
      <div v-if="selectedCell && selectedCell.state === 'cancel'" class="p-1">
        <div>
          <b-row class=""  v-if="selectedCell.role_id">
            <span :key="role_id.id" :index="role_id.id" v-for="role_id in selectedCell.role_id ">
              <del><h6 style="margin-right: 5px">{{role_id.label}},</h6></del>
            </span>
          </b-row>
          <b-row v-if="selectedCell.service">
            <b-badge size="sm" class="mr-1" v-if="selectedCell.is_covid_shift" variant="danger">
              COVID-19
            </b-badge>
            <b-badge size="sm" v-if="selectedCell.service.type ==='clinical'" :variant="'light-success'" class="text-capitalize">
              <del>{{selectedCell.service.type}}</del>
            </b-badge>
            <b-badge size="sm" v-if="selectedCell.service.type ==='non-clinical'" :variant="'light-info'" class="text-capitalize">
              <del>{{selectedCell.service.type}}</del>
            </b-badge>
          </b-row>
          <b-row class="mt-1" v-if="selectedCell">
            <feather-icon icon="CalendarIcon" class="mr-1" />
            <del>{{momentFormat(selectedCell.StartTime,'ddd DD MMMM YYYY')}}</del>
          </b-row>
          <b-row class="mt-1" v-if="selectedCell">
            <feather-icon icon="ClockIcon" class="mr-1"  />
            <del>{{momentFormat(selectedCell.StartTime,'HH:mm')}} - {{momentFormat(selectedCell.EndTime,'HH:mm')}}</del>
          </b-row>
          <b-row class="mt-1">
            <feather-icon icon="MapPinIcon" class="mr-1"  />
           <del>{{selectedCell.practice_name}}</del>
          </b-row>
          <b-row class="mt-1" v-if="selectedCell.user">
            <feather-icon icon="UsersIcon" class="mr-1"  />
            <del>{{selectedCell.user.first_name +' ' +selectedCell.user.last_name}}</del>
          </b-row>
          <b-row class="mt-1 applications" v-if="selectedCell.State ==='pending'" @click="viewApplications()"  >
            <spn class="applicationIcon">
              <feather-icon icon="UsersIcon" />
              <span class="">2</span>
            </spn>
            <span class="applicationText">View Applications</span>
          </b-row>
          <b-row class="mt-1"  v-if="!selectedCell.user">
            <feather-icon icon="UsersIcon" class="mr-1"  />
            <span :key="jobRole.id" :index="jobRole.id" v-for="jobRole in selectedCell.vacant_job_roles ">
            <del><span style="margin-right: 5px">{{jobRole.job_role.name}},</span></del>
            </span>
          </b-row>
          <b-row class="mt-1 applications" v-if="selectedCell.applications_count !== 0"  >
            <span class="applicationIcon" style="margin-bottom:2px; background-color: black; padding:2px; width: 35px;border-radius: 8px">
              <feather-icon icon="UsersIcon" class="userIcon"/>
              <del><span class="" style="padding-left: 5px;">{{selectedCell.applications_count}}</span></del>
            </span>
            <span class="applicationText" @click="editEvent()">View Applications</span>
          </b-row>
        </div>
      </div>
    </div>
</template>

<script>
import MomentMixin from '@/mixins/MomentMixin'
import {
  BRow,
  BCol,
  BCard,
  BCardText,
  BLink,
  BListGroup,
  BListGroupItem,
  BCardTitle,
  BCardBody,
  BFormGroup,
  BButton,
  BSidebar,
  BModal, BAlert,
  BBadge,
  BButtonGroup, VBToggle, VBModal,
} from 'bootstrap-vue'
import SideBar from "@/views/scheduler/sideBar";
import Ripple from 'vue-ripple-directive'

  export default {
    name: 'content',
    mixins: [MomentMixin],
    components: {
      SideBar,
      BRow,
      BCol,
      BCard,
      BCardText,
      BLink,
      BCardTitle,
      BListGroup,
      BListGroupItem,
      BCardBody,
      BFormGroup,
      BButton,
      BSidebar,
      BModal,
      BAlert,
      BBadge,
      BButtonGroup,
    },
    directives: {
      Ripple,
    },
    data () {
      return {
        selectedCell:[],
        fields: { text: 'CalendarName', value: 'CalendarId' },
        roomData: [
          { CalendarName: 'My Calendar', CalendarId: 1, CalendarColor: '#c43081' },
          { CalendarName: 'Company', CalendarId: 2, CalendarColor: '#ff7f50' },
          { CalendarName: 'Birthday', CalendarId: 3, CalendarColor: '#AF27CD' },
          { CalendarName: 'Holiday', CalendarId: 4, CalendarColor: '#808000' }
        ],
        data: {}
      }
    },
    methods: {
      async viewApplications(){
        this.$root.$emit('indexComponentFormEventTemplateViewApplication') //like this
      },

      async editEvent(){
        this.$root.$emit('indexComponentFormEventTemplate') //like this
      },

    },
    mounted () {
      this.selectedCell = this.data
    }
  }
</script>

<style>
.applicationIcon{
  background-color: black; color: white ; border-radius: 5px ; padding: 2px
}
.applicationText{
  padding: 2px;
  margin-left: 5px;
  color:  #1B9AAA;
}
.applications{
  cursor: pointer;
}

.userIcon{
  padding-left: 3px;
}


</style>
