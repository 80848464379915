import api from '../axios';
const resource = '/api/mp/dashboard/';


export default {
  getSummery: () => api.get(`${resource}summery`),
  getShiftApplications: () => api.get(`${resource}practice-admin/shift-applications`),
  acceptApplicationInvitation: (id) => api.post(`${resource}practice-admin/shift-applications/${id}/accept`),
  rejectApplicationInvitation: (id, user_id) => api.post(`${resource}practice-admin/shift-applications/${id}/reject/${user_id}`),


  // swap request
  getSwapApplications: () => api.get(`${resource}practice-admin/swap-applications`),
  acceptApplicationISwap: (id) => api.post(`${resource}practice-admin/swap-applications/${id}/accept`),
  rejectApplicationSwap: (id) => api.post(`${resource}practice-admin/swap-applications/${id}/rejected`),

  getConnectionRequests: (filterQuery, sort = '', desc = true) =>
    api.get(
      `${resource}connections/requests?sort=${desc ? '' : '-'}${sort}&${filterQuery}`),

  connectionRequestAccept: (id) =>
    api.post(`api/mp/pcn/connections/requests/${id}/accept`),
  connectionRequestReject: (id) => api.post(`api/mp/pcn/connections/requests/${id}/decline`),
  updateDashboard : (payload) => api.post(`${resource}update-dashboard`,payload),
  getUpdateDashboardDetails: () =>api.get(`${resource}get-dashboard-details`),
  getTimeSheets: () =>  api.get(`${resource}time_sheets/users`),
  getLeaveRequests: () =>
    api.get(
      `${resource}practice-admin/leaves/pending-leaves`
    ),
  acceptLeave: (id) =>
    api.post(`api/mp/practice-admin/leaves/${id}/approve`),

  rejectLeave: (id) =>
    api.post(`api/mp/practice-admin/leaves/${id}/reject`),

  getLocumRequests:(filterQuery) => api.get(`${resource}practice/locum-requests?filter[state]=approval pending&${filterQuery}`),
  approveLocumRequests:(id) => api.post(`api/mp/practice/locum-requests/${id}/approve`),
  declineLocumRequests:(id) => api.post(`api/mp/practice/locum-requests/${id}/decline`),
  getInvoicesList :(filterQuery) => api.get(`${resource}invoices?${filterQuery}`),


  getPcnStaffJobRoles: (filterQuery,pcnID) => api.get(`/api/pcn/staff-job-roll-wise-to-be-block?${filterQuery}&auth_pcn_id=${pcnID}`),
};
