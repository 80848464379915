<template>

  <b-sidebar
      id="add-new-user-sidebar"
      :visible="isDuplicateSingleShiftSidebarActive"
      backdrop
      bg-variant="white"
      no-header
      right
      shadow
      sidebar-class="sidebar-lg"
      @change="(val) => $emit('update:is-duplicate-single-shift-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <b-overlay
          :show="loading"
          rounded="sm"
      >
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            Duplicate Single Shift
          </h5>
          <!--        - <span v-if="data.event.Subject">{{data.event.Subject}}</span>-->
          <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
          />
        </div>
        <div class="p-2">
          <validation-observer ref="duplicateShiftForm">
          <b-row>
            <b-col md="12">
              <p>shift will be duplicated as drafts. To make them visible to staff, you will need to publish rota.
              </p>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12">
              <p class="font-weight-bold font-weight-700">Selected Shift:</p>
            </b-col>
          </b-row>
<!--            {{data}}-->

          <b-row>
            <b-col cols="12" md="12">
              <label>Date</label>
              <b-form-group>
                <flat-pickr
                    v-model="copy_date"
                    :config="{ enableTime: false, noCalendar: false, dateFormat: 'd/m/y',}"
                    class="form-control"
                    disabled
                    placeholder="Select date"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
              <label>Start Time</label>

              <b-form-group>
                <flat-pickr
                    v-model="copy_to_start"
                    :config="{ enableTime: true, noCalendar: true, dateFormat: 'H:i:ss', }"
                    class="form-control"
                    disabled
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
              <label>End Time </label>

              <b-form-group>
                <flat-pickr
                    v-model="copy_to_end"
                    :config="{ enableTime: true, noCalendar: true, dateFormat: 'H:i:ss', }"
                    class="form-control"
                    disabled
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12">
              <p class="font-weight-bold font-weight-700">Paste to:</p>
            </b-col>
          </b-row>
          <b-row>

          </b-row>


          <b-row>
            <b-col cols="12" md="12">
              <label>Date</label>

              <b-form-group>
                <validation-provider
                    #default="{ errors }"
                    name="End Time"
                    rules="required"
                >
                <flat-pickr

                    v-model="paste_to_day"
                    :config="{ enableTime: false, noCalendar: false, minDate: new Date(), dateFormat: 'd/m/y',mode:'multiple', locale: {firstDayOfWeek: 1}}"
                    class="form-control"
                    placeholder="Select shift paste  date"
                />
                  <small   class="text-danger" v-if="errors[0]">Select shift paste data</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col class="mb-1" cols="12">
              <b-form-checkbox
                  v-model="isVacant"
                  value="true"

              >
                Vacant Shift
              </b-form-checkbox>

            </b-col>

            <b-col cols="12" md="6" v-if="isVacant">

              <b-form-group>
                <label>Professions</label>
                <v-select
                    v-model="selectedVacantRole"
                    :options="professionList"
                    label="text"
                    placeholder="Select Professions"
                    multiple
                    :reduce="professionList => professionList.id"
                />
              </b-form-group>
            </b-col>


<!--            <b-col cols="12" md="6" v-if="!isVacant">-->

<!--              <b-form-group>-->
<!--                <label>Professions</label>-->
<!--                <v-select-->
<!--                    v-model="selectedProfessionsForEdit"-->
<!--                    :options="groups.filter(m => m.id !== -1)"-->
<!--                    label="text"-->
<!--                    placeholder="Select Role"-->
<!--                />-->
<!--              </b-form-group>-->
<!--            </b-col >-->
            <b-col cols="12" md="6" v-if="!isVacant">

              <b-form-group>
                <label>Professions</label>
                <v-select
                    v-model="selectedProfessionsForEdit"
                    :options="groups.filter(m => m.id !== -1)"
                    label="text"
                    placeholder="Select Role"
                />
              </b-form-group>
            </b-col >
                        <b-col cols="12" md="6" v-if="isVacant">
              <b-form-group>
                <label>Role (Optional)</label>
                <v-select
                    v-model="selectedRoleForEdit"
                    :options="professionsList"
                    label="text"
                    placeholder="Select Role"
                    multiple
                    :reduce="professionsList => professionsList.value"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6" v-if="!isVacant">
              <b-form-group >
                 <label>Healthcare Professional</label>
                  <validation-provider
                    #default="{ errors }"
                    name="End Time"
                    rules="required"
                >
               <v-select

                    v-model="selectedHealthcare_professionalForEdit"
                    :options="users.filter(x => x.groupId === selectedProfessionsForEdit.val)"
                    label="text"
                    placeholder="Select healthcare professional"
                />
                  <small   class="text-danger" v-if="errors[0]">Select healthcare professional</small>
                </validation-provider>

              </b-form-group>
            </b-col>


          </b-row>

          <div class="flex mt-5">
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="duplicateFormSubmit()"
            >
              Duplicate
            </b-button>
            <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                class="text-primary ml-1 shadow"
                variant="outline-white"
                @click="isDuplicateSingleShiftSidebarActive = false"
            >
              Cancel
            </b-button>
          </div>
          </validation-observer>
        </div>
      </b-overlay>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardText,
  BFormGroup,
  BButton,
  BSidebar,
  BFormCheckbox,
   VBToggle, VBModal, BOverlay
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import moment from 'moment'
import MomentMixin from '@/mixins/MomentMixin'
import shift from '@/apis/modules/shift'
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {required} from '@validations'
import SettingsAPI from '@/apis/modules/settings'
import settings from '@/apis/modules/settings'


export default {
  name: 'duplicateSingleShiftSideBar',
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BFormGroup,
    BButton,
    BSidebar,
    BFormCheckbox,
    flatPickr,
    BOverlay,
    ValidationObserver,
    ValidationProvider,

  },
  mixins: [MomentMixin],
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-modal': VBModal
  },
  data () {
    return {
      selectedRoleForEdit:[],
      selectedProfessionsForEditCopy:{},
      selectedVacantRole:[],
      professionList:[],
      professionsList:[],
      required,
      isVacant: false,
      selectedHealthcare_professionalForEdit: {},
      selectedProfessionsForEdit: {},
      selectedRole: [],
      selectedHealthcare_professional: [],
      projectResourceDataSource: [],
      employeeDataSource: [],
      loading: false,
      selectedRange: '',
      dateOption: [],
      data: '',
      selected: '',
      timePicker: '',
      selectedMultiple: '',
      copy_date: null,
      copy_to_start: null,
      copy_to_end: null,
      paste_to_day: null,
      paste_to_start: null,
      paste_to_end: null,
      visible_locums: '',

      formDuplicate: {
        hourly_rate: '',
        start: '',
        end: '',
        note: '',
        break_minutes: '',
        service_id: '',
        assignee_id: '',
        rate: '',
        salary_staff_rate_type: '',
        salary_staff_rate: null,
        locum_rate: null
      }

    }
  },
  model: {
    prop: 'isDuplicateSingleShiftSidebarActive',
    event: 'update:is-duplicate-single-shift-sidebar-active'
  },
  props: {
    isDuplicateSingleShiftSidebarActive: {
      type: Boolean,
      required: true
    },
    cellData: {
      type: Object,
      required: true
    },
    users: {
      type: Array,
      required: true
    },
    groups: {
      type: Array,
      required: true
    }
  },
  watch: {
    async cellData () {
      this.data = this.cellData.event
      await this.dataSet()
    },
    selectedVacantRole(val){
      this.filterJobRoles(val);
    },
    isDuplicateSingleShiftSidebarActive (val) {
      if (!val) {
        this.formDuplicate = {}
        this.paste_to_day = {}
        this.selectedRoleForEdit = []
      }
    },

    selectedProfessionsForEdit(val) {
      if(this.selectedProfessionsForEdit !== this.selectedProfessionsForEditCopy){
        this.selectedHealthcare_professionalForEdit = ''
      }
      this.filterJobRoles(val);
    }
  },
  methods: {
    async filterJobRoles(val, id = null) {
      try {
        //  this.loading = true;
        let allRoles = [];
        const Response = await settings.getRolesWithoutPagination();
        allRoles = Response.data.data.map((x) => ({
          text: x.name,
          value: x.id,
          profession: x.professions,
        }));

        let selectedRoleIDS = [];
        allRoles.map((x) => {
          x.profession.map((y) => {
            val.map((k) => {
              if (k === y) selectedRoleIDS.push(x.value);
            });
          });
        });

        this.professionsList = selectedRoleIDS.map((x) => {
          return allRoles.find((e) => e.value === x);
        });

        this.professionsList = Array.from(
            new Set(this.professionsList.map((a) => a.value))
        ).map((value) => {
          return this.professionsList.find((a) => a.value === value);
        });
        // this.loading = false;
      } catch (e) {}
    },
    async getAllProfessions(){
      try {
        const Response = await SettingsAPI.getJobRoleList()
        this.professionList =  Response.data.data.map((x) => ({
              id: x.id,
              text: x.name,
              job_role_id: x.id,
              standard_rate_p_h: '',
              enhanced_rate_p_h: '',
              locum_rate_p_h: ''
            })
        )
        if (this.data.vacant_job_roles !== null){
          this.selectedVacantRole = await this.data.vacant_job_roles.map(x => x.job_role.id)
        }

      }catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async dataSet () {
      this.loading = true
      this.formDuplicate.break_minutes = this.data.break_minutes
      this.formDuplicate.rate = this.data.rate
      this.formDuplicate.note = this.data.note
      this.formDuplicate.locum_rate = this.data.locum_rate
      this.formDuplicate.salary_staff_rate = this.data.salary_staff_rate
      this.formDuplicate.salary_staff_rate_type = this.data.salary_staff_rate_type
      this.formDuplicate.service_id = this.data.service.length !== 0 ? this.data.service.id : null
      this.formDuplicate.shift_type_id = this.data.shift_type.length !== 0 ? this.data.shift_type.id : null
      this.visible_locums = !!this.data.is_visible_locums
      this.isVacant = this.data.user === null
      // this.copy_date = this.momentFormat(new Date(), 'DD/MM/YY')
      this.copy_date = this.momentFormat(this.data.StartTime, 'DD/MM/YY')
      this.copy_to_start = this.momentFormat(this.data.StartTime, 'HH:mm')
      this.copy_to_end = this.momentFormat(this.data.EndTime, 'HH:mm')
      this.projectResourceDataSource = this.groups
      if(this.data.role_id[0] != undefined){
        this.data.role_id.forEach((x)=>{
          this.selectedRoleForEdit.push(x.value)
        })
      }
      if(this.data.job_role !== null){
        await this.getAllProfessions()
        this.selectedProfessionsForEdit = Object(this.groups.filter(m => m.id === this.data.job_role.id))[0]
        this.selectedProfessionsForEditCopy = Object(this.groups.filter(m => m.id === this.data.job_role.id))[0]
        this.selectedHealthcare_professionalForEdit = Object(this.users.filter(m => m.id === this.data.user_id))[0]
      }else {
        await this.getAllProfessions()

      }
      this.loading = false
    },
    async duplicateFormSubmit () {
      // if (await this.$refs.editShiftForm.validate()) {
      if (await this.$refs.duplicateShiftForm.validate()) {
        try {

          console.log('ava mehe')
          this.loading = true
          for (let value of this.paste_to_day.split(',')) {

            this.formDuplicate.start = moment((value.trim()+' '+ this.copy_to_start),'DD-MM-YYYY HH:mm:ss').utc().format('YYYY-MM-DD HH:mm:ss')
            this.formDuplicate.end = moment((value.trim()+' '+ this.copy_to_end),'DD-MM-YYYY HH:mm:ss').utc().format('YYYY-MM-DD HH:mm:ss')
          if (this.isVacant) {

            this.formDuplicate.assignee_id = ''
            this.formDuplicate.job_role_id = ''
            this.formDuplicate.job_role_ids = this.selectedVacantRole
            this.formDuplicate.role_id = this.selectedRoleForEdit

            await shift.createVacantShift(this.formDuplicate)
          } else {
            this.formDuplicate.role_id = this.selectedRoleForEdit
            this.formDuplicate.job_role_id = this.selectedProfessionsForEdit.id
            this.formDuplicate.assignee_id = this.selectedHealthcare_professionalForEdit.id
            await shift.createShift(this.formDuplicate)
          }



          // this.formEdit.start=  this.selectedDateForEdit + ` `+this.selectedStartTime
          // this.formEdit.end= this.selectedDateForEdit+ ` ` + this.selectedEndTime


          }
          this.showSuccessMessage('Shift Duplicated Successfully')
          this.loading = false
          this.$emit('editShift')
        } catch (error) {
          this.convertAndNotifyError(error)
          this.loading = false
        }
      }
    }
  },
  mounted () {
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

</style>



