var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "chell" }, [
    _vm.data.state !== "leave" && _vm.data.state !== "un-availability"
      ? _c(
          "div",
          {
            staticClass: "template-wrapper",
            class: { borderBind: _vm.data.state === "draft" },
            style: {
              background:
                _vm.data.applications_count !== 0 ? "#EFA12C" : _vm.data.color
            }
          },
          [
            _c("div", { staticClass: "time" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.momentFormat(_vm.data.StartTime, "HH:mm") + " - "
                  ) +
                  " "
              ),
              _c("br"),
              _vm._v(
                " " + _vm._s(_vm.momentFormat(_vm.data.EndTime, "HH:mm")) + " "
              )
            ]),
            _vm.data.Subject !== "." && _vm.data.state !== "leave"
              ? _c(
                  "div",
                  {
                    staticClass: "subject",
                    staticStyle: { "margin-top": "3px" }
                  },
                  [
                    _vm.data.state === "draft"
                      ? _c(
                          "div",
                          {
                            staticStyle: {
                              overflow: "hidden",
                              "text-overflow": "ellipsis",
                              "background-color": "#FFFFFF",
                              "border-radius": "3px",
                              padding: "1px",
                              color: "#4361EE"
                            }
                          },
                          [_vm._v(" " + _vm._s(_vm.data.Subject))]
                        )
                      : _c(
                          "div",
                          {
                            staticStyle: {
                              overflow: "hidden",
                              "text-overflow": "ellipsis",
                              "background-color": "#FFFFFF",
                              "border-radius": "3px",
                              padding: "1px",
                              color: "#00C49A",
                              "word-wrap": "break-word"
                            }
                          },
                          [_vm._v(" " + _vm._s(_vm.data.Subject))]
                        )
                  ]
                )
              : _vm._e(),
            _vm.data.applications_count !== 0
              ? _c(
                  "div",
                  {
                    staticClass: "text-white font-weight-bold font-weight-700",
                    staticStyle: {
                      "margin-top": "4px",
                      "background-color": "black",
                      padding: "2px",
                      width: "57px",
                      "border-radius": "8px"
                    }
                  },
                  [
                    _c(
                      "span",
                      { staticClass: "p-1" },
                      [
                        _c("feather-icon", { attrs: { icon: "UserIcon" } }),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(_vm._s(_vm.data.applications_count))
                        ])
                      ],
                      1
                    )
                  ]
                )
              : _vm._e()
          ]
        )
      : _vm._e(),
    _vm.data.state === "leave"
      ? _c("div", { staticClass: "template-wrapper" }, [
          _c("div", { staticClass: "float-left " }, [
            _c(
              "svg",
              {
                attrs: {
                  fill: "none",
                  height: "15",
                  viewBox: "0 0 12 12",
                  width: "15",
                  xmlns: "http://www.w3.org/2000/svg"
                }
              },
              [
                _c("path", {
                  attrs: {
                    d:
                      "M9 4H9.5C10.0304 4 10.5391 4.21071 10.9142 4.58579C11.2893 4.96086 11.5 5.46957 11.5 6C11.5 6.53043 11.2893 7.03914 10.9142 7.41421C10.5391 7.78929 10.0304 8 9.5 8H9",
                    stroke: "#1B9AAA",
                    "stroke-linecap": "round",
                    "stroke-linejoin": "round"
                  }
                }),
                _c("path", {
                  attrs: {
                    d:
                      "M1 4H9V8.5C9 9.03043 8.78929 9.53914 8.41421 9.91421C8.03914 10.2893 7.53043 10.5 7 10.5H3C2.46957 10.5 1.96086 10.2893 1.58579 9.91421C1.21071 9.53914 1 9.03043 1 8.5V4Z",
                    stroke: "#1B9AAA",
                    "stroke-linecap": "round",
                    "stroke-linejoin": "round"
                  }
                }),
                _c("path", {
                  attrs: {
                    d: "M3 0.5V2",
                    stroke: "#1B9AAA",
                    "stroke-linecap": "round",
                    "stroke-linejoin": "round"
                  }
                }),
                _c("path", {
                  attrs: {
                    d: "M5 0.5V2",
                    stroke: "#1B9AAA",
                    "stroke-linecap": "round",
                    "stroke-linejoin": "round"
                  }
                }),
                _c("path", {
                  attrs: {
                    d: "M7 0.5V2",
                    stroke: "#1B9AAA",
                    "stroke-linecap": "round",
                    "stroke-linejoin": "round"
                  }
                })
              ]
            )
          ]),
          _vm.data.is_day
            ? _c("p", { staticClass: " ml-2 text-dark" }, [_vm._v("Full Day")])
            : _vm._e(),
          _vm.data.state === "leave"
            ? _c("p", { staticClass: " ml-2 text-dark" }, [
                _vm._v(
                  _vm._s(
                    _vm.momentFormat(_vm.data.StartTime, "HH:mm") +
                      " - " +
                      _vm.momentFormat(_vm.data.EndTime, "HH:mm")
                  )
                )
              ])
            : _vm._e()
        ])
      : _vm._e(),
    _vm.data.state === "un-availability"
      ? _c("div", { staticClass: "unavailable" }, [
          _c("span", { staticClass: "  text-dark " }, [
            _vm._v("User unavailable")
          ]),
          _c("p", { staticClass: "  text-dark " }, [
            _vm._v(
              _vm._s(
                _vm.momentFormat(_vm.data.StartTime, "HH:mm") +
                  " - " +
                  _vm.momentFormat(_vm.data.EndTime, "HH:mm")
              )
            )
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }