<template>
  <div>
    <div className="template-wrap">
      <div class="employee-category" v-bind:class="{ active: isGroup() }">
        <div>
          <img
            @click="$router.push(`/staff-bank/${uid}`)"
            v-if="!isGroup() && !isVacant()"
            class="employee-image"
            :src="setImage()"
            alt=""
          />

          <svg
            v-if="isVacant()"
            class="clock-image"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path
              fill="#4361EE"
              d="M12.5 7.25a.75.75 0 00-1.5 0v5.5c0 .27.144.518.378.651l3.5 2a.75.75 0 00.744-1.302L12.5 12.315V7.25z"
            ></path>
            <path
              fill="#4361EE"
              d="M12 1C5.925 1 1 5.925 1 12s4.925 11 11 11 11-4.925 11-11S18.075 1 12 1zM2.5 12a9.5 9.5 0 1119 0 9.5 9.5 0 01-19 0z"
            ></path>
          </svg>
        </div>
        <div
          class="employee_name flex"
          style="padding-bottom: 1px; padding-top: 2px !important"
        >
          <div class="" v-bind:class="{ employee: !isGroup() }">
            {{
              getEmployeeName().slice(0, 25) +
              (getEmployeeName().length > 25 ? "..." : "")
            }}
          </div>
          <span
            v-if="isLocum()"
            style="
              padding: 8px;
              width: 20px;
              background: #cce6f4;
              border-radius: 8px;
              font-weight: bold;
            "
            class="text-dark font-weight-700"
          >
            L
          </span>
          <!--          <span  class="ml-1 float-right" style="padding:8px; width: 20px; background: #CCE6F4; border-radius: 8px; font-weight: bold"  >-->

          <!--            </span>-->
        </div>
        <!--        <div class="employee-designation">-->
        <!--          {{ getEmployeeDesignation() }}-->
        <!--        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
import { BAvatar, BButton, BBadge } from "bootstrap-vue";

export default {
  name: "Header",
  data() {
    return {
      data: {},
      uid: "",
    };
  },
  component: {
    BAvatar,
    BButton,
    BBadge,
  },
  computed: {},

  mounted() {},
  methods: {
    isLocum() {
      const value = JSON.parse(JSON.stringify(this.data));
      return value.resourceData.is_locum;
    },
    getEmployeeName() {
      const value = JSON.parse(JSON.stringify(this.data));
      return value.resourceData
        ? value.resourceData[value.resource.textField]
        : value.resourceName;
    },
    getEmployeeImage() {
      const value = JSON.parse(JSON.stringify(this.data));
      return value.resourceData.image;
      //  return (`../../assets/images/avatars/${value.resourceData.image}`)
    },
    isGroup() {
      const value = JSON.parse(JSON.stringify(this.data));
      return value.resourceData.isGroup;
    },
    isVacant() {
      const value = JSON.parse(JSON.stringify(this.data));

      return value.resourceData.groupId === -1;
    },

    setImage() {
      const value = JSON.parse(JSON.stringify(this.data));
      this.uid = value.resourceData.id;
      if (value.resourceData.is_have_profile) {
        const image = this.getUserImage(value.resourceData.id, true);
        return image;
      } else {
        // return '../../assets/images/avatars/1.png'
        return require("@/assets/images/avatars/1.png");
      }
    },
  },
};
</script>

<style>
.schedule-vue-sample
  .e-schedule:not(.e-device)
  .e-agenda-view
  .e-content-wrap
  table
  td:first-child {
  width: 90px;
}

.schedule-vue-sample .e-schedule .e-agenda-view .e-resource-column {
  width: 100px;
}
.schedule-vue-sample .block-events.e-schedule .template-wrap {
  width: 100%;
}

.schedule-vue-sample
  .block-events.e-schedule
  .e-vertical-view
  .e-resource-cells {
  height: 58px;
}

.schedule-vue-sample
  .block-events.e-schedule
  .e-timeline-view
  .e-resource-left-td,
.schedule-vue-sample
  .block-events.e-schedule
  .e-timeline-month-view
  .e-resource-left-td {
  width: 170px;
}

.schedule-vue-sample
  .block-events.e-schedule
  .e-resource-cells.e-child-node
  .employee-category,
.schedule-vue-sample
  .block-events.e-schedule
  .e-resource-cells.e-child-node
  .employee-name {
  padding: 1px;
}

.employee-image {
  width: 45px;
  height: 40px;
  float: left;
  border-radius: 50%;
  margin-right: 10px;
}

.clock-image {
  width: 35px;
  height: 35px;
  float: left;
  border-radius: 50%;
  margin-top: 8px;
  margin-left: 5px;
  margin-right: 10px;
  background-color: #eceffd;
}

.employee-image:hover {
  cursor: pointer;
}
[dir] .e-schedule .e-timeline-view .e-resource-tree-icon {
  padding-bottom: 0px !important;
  padding-top: 0 !important;
  margin-top: 0 !important;
}
.employee_name {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
  /*font-size: 11px;*/
}

.employee {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  margin-top: 4px;
}

/* .employee-designation {
  font-size: 10px;
} */
.e-schedule .e-timeline-view .e-resource-cells.e-parent-node {
  /*font-size: 10px;*/
  height: 30px !important;
  padding-top: 0 !important;

  background-color: #cce6f4;
  /*background-color: red;*/

  /*height: 10px!important;*/
}

[dir] .e-schedule .e-timeline-view .e-resource-tree-icon {
  padding-bottom: 0px;
  padding-top: 0px;
  margin-top: 0px;
}

image {
  background-image: -webkit-image-set(
    url("../../../src/assets/images/avatars/1.png") 1x,
    url("../../../src/assets/images/avatars/1.png") 2x
  );
  background-image: image-set(
    url("../../../src/assets/images/avatars/1.png") 1x,
    url("../../../src/assets/images/avatars/1.png") 2x
  );
}
</style>
