var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.selectedCell && _vm.selectedCell.state !== "cancel"
      ? _c("div", { staticClass: "p-1" }, [
          _c(
            "div",
            [
              _vm.selectedCell.role_id
                ? _c(
                    "b-row",
                    {},
                    _vm._l(_vm.selectedCell.role_id, function(role_id) {
                      return _c(
                        "span",
                        { key: role_id.id, attrs: { index: role_id.id } },
                        [
                          _c("h6", { staticStyle: { "margin-right": "5px" } }, [
                            _vm._v(_vm._s(role_id.label) + ",")
                          ])
                        ]
                      )
                    }),
                    0
                  )
                : _vm._e(),
              _vm.selectedCell.service
                ? _c(
                    "b-row",
                    [
                      _vm.selectedCell.is_covid_shift
                        ? _c(
                            "b-badge",
                            {
                              staticClass: "mr-1",
                              attrs: { size: "sm", variant: "danger" }
                            },
                            [_vm._v(" COVID-19 ")]
                          )
                        : _vm._e(),
                      _vm.selectedCell.service.type === "clinical"
                        ? _c(
                            "b-badge",
                            {
                              staticClass: "text-capitalize",
                              attrs: { size: "sm", variant: "light-success" }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.selectedCell.service.type) +
                                  " "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm.selectedCell.service.type === "non-clinical"
                        ? _c(
                            "b-badge",
                            {
                              staticClass: "text-capitalize",
                              attrs: { size: "sm", variant: "light-info" }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.selectedCell.service.type) +
                                  " "
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _vm.selectedCell
                ? _c(
                    "b-row",
                    { staticClass: "mt-1" },
                    [
                      _c("feather-icon", {
                        staticClass: "mr-1",
                        attrs: { icon: "CalendarIcon" }
                      }),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.momentFormat(
                              _vm.selectedCell.StartTime,
                              "ddd DD MMMM YYYY"
                            )
                          ) +
                          " "
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.selectedCell
                ? _c(
                    "b-row",
                    { staticClass: "mt-1" },
                    [
                      _c("feather-icon", {
                        staticClass: "mr-1",
                        attrs: { icon: "ClockIcon" }
                      }),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.momentFormat(
                              _vm.selectedCell.StartTime,
                              "HH:mm"
                            )
                          ) +
                          " - " +
                          _vm._s(
                            _vm.momentFormat(_vm.selectedCell.EndTime, "HH:mm")
                          ) +
                          " "
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.selectedCell.break_minutes
                ? _c(
                    "b-row",
                    { staticClass: "mt-1" },
                    [
                      _c("feather-icon", {
                        staticClass: "mr-1",
                        attrs: { icon: "ClockIcon" }
                      }),
                      _vm._v(
                        " (" + _vm._s(_vm.selectedCell.break_minutes) + " m) "
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "b-row",
                { staticClass: "mt-1" },
                [
                  _c("feather-icon", {
                    staticClass: "mr-1",
                    attrs: { icon: "MapPinIcon" }
                  }),
                  _vm._v(" " + _vm._s(_vm.selectedCell.practice_name) + " ")
                ],
                1
              ),
              _vm.selectedCell.user
                ? _c(
                    "b-row",
                    { staticClass: "mt-1" },
                    [
                      _c("feather-icon", {
                        staticClass: "mr-1",
                        attrs: { icon: "UsersIcon" }
                      }),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.selectedCell.user.first_name +
                              " " +
                              _vm.selectedCell.user.last_name
                          ) +
                          " "
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.selectedCell.State === "pending"
                ? _c(
                    "b-row",
                    {
                      staticClass: "mt-1 applications",
                      on: {
                        click: function($event) {
                          return _vm.viewApplications()
                        }
                      }
                    },
                    [
                      _c(
                        "spn",
                        { staticClass: "applicationIcon" },
                        [
                          _c("feather-icon", { attrs: { icon: "UsersIcon" } }),
                          _c("span", {}, [_vm._v("2")])
                        ],
                        1
                      ),
                      _c("span", { staticClass: "applicationText" }, [
                        _vm._v("View Applications")
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.selectedCell.user
                ? _c(
                    "b-row",
                    { staticClass: "mt-1" },
                    [
                      _c("feather-icon", {
                        staticClass: "mr-1",
                        attrs: { icon: "UsersIcon" }
                      }),
                      _vm._l(_vm.selectedCell.vacant_job_roles, function(
                        jobRole
                      ) {
                        return _c(
                          "span",
                          { key: jobRole.id, attrs: { index: jobRole.id } },
                          [
                            _c(
                              "span",
                              { staticStyle: { "margin-right": "5px" } },
                              [_vm._v(_vm._s(jobRole.job_role.name) + ",")]
                            )
                          ]
                        )
                      })
                    ],
                    2
                  )
                : _vm._e(),
              _vm.selectedCell.shift_type
                ? _c("b-row", { staticClass: "mt-1" }, [
                    _c("h6", [_vm._v(_vm._s(_vm.selectedCell.shift_type.name))])
                  ])
                : _vm._e(),
              _vm.selectedCell.applications_count !== 0
                ? _c("b-row", { staticClass: "mt-1 applications" }, [
                    _c(
                      "span",
                      {
                        staticClass: "applicationIcon",
                        staticStyle: {
                          "margin-bottom": "2px",
                          "background-color": "black",
                          padding: "2px",
                          width: "35px",
                          "border-radius": "8px"
                        }
                      },
                      [
                        _c("feather-icon", {
                          staticClass: "userIcon",
                          attrs: { icon: "UsersIcon" }
                        }),
                        _c("span", { staticStyle: { "padding-left": "5px" } }, [
                          _vm._v(_vm._s(_vm.selectedCell.applications_count))
                        ])
                      ],
                      1
                    ),
                    _c(
                      "span",
                      {
                        staticClass: "applicationText",
                        on: {
                          click: function($event) {
                            return _vm.editEvent()
                          }
                        }
                      },
                      [_vm._v("View Applications")]
                    )
                  ])
                : _vm._e()
            ],
            1
          )
        ])
      : _vm._e(),
    _vm.selectedCell && _vm.selectedCell.state === "cancel"
      ? _c("div", { staticClass: "p-1" }, [
          _c(
            "div",
            [
              _vm.selectedCell.role_id
                ? _c(
                    "b-row",
                    {},
                    _vm._l(_vm.selectedCell.role_id, function(role_id) {
                      return _c(
                        "span",
                        { key: role_id.id, attrs: { index: role_id.id } },
                        [
                          _c("del", [
                            _c(
                              "h6",
                              { staticStyle: { "margin-right": "5px" } },
                              [_vm._v(_vm._s(role_id.label) + ",")]
                            )
                          ])
                        ]
                      )
                    }),
                    0
                  )
                : _vm._e(),
              _vm.selectedCell.service
                ? _c(
                    "b-row",
                    [
                      _vm.selectedCell.is_covid_shift
                        ? _c(
                            "b-badge",
                            {
                              staticClass: "mr-1",
                              attrs: { size: "sm", variant: "danger" }
                            },
                            [_vm._v(" COVID-19 ")]
                          )
                        : _vm._e(),
                      _vm.selectedCell.service.type === "clinical"
                        ? _c(
                            "b-badge",
                            {
                              staticClass: "text-capitalize",
                              attrs: { size: "sm", variant: "light-success" }
                            },
                            [
                              _c("del", [
                                _vm._v(_vm._s(_vm.selectedCell.service.type))
                              ])
                            ]
                          )
                        : _vm._e(),
                      _vm.selectedCell.service.type === "non-clinical"
                        ? _c(
                            "b-badge",
                            {
                              staticClass: "text-capitalize",
                              attrs: { size: "sm", variant: "light-info" }
                            },
                            [
                              _c("del", [
                                _vm._v(_vm._s(_vm.selectedCell.service.type))
                              ])
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _vm.selectedCell
                ? _c(
                    "b-row",
                    { staticClass: "mt-1" },
                    [
                      _c("feather-icon", {
                        staticClass: "mr-1",
                        attrs: { icon: "CalendarIcon" }
                      }),
                      _c("del", [
                        _vm._v(
                          _vm._s(
                            _vm.momentFormat(
                              _vm.selectedCell.StartTime,
                              "ddd DD MMMM YYYY"
                            )
                          )
                        )
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _vm.selectedCell
                ? _c(
                    "b-row",
                    { staticClass: "mt-1" },
                    [
                      _c("feather-icon", {
                        staticClass: "mr-1",
                        attrs: { icon: "ClockIcon" }
                      }),
                      _c("del", [
                        _vm._v(
                          _vm._s(
                            _vm.momentFormat(
                              _vm.selectedCell.StartTime,
                              "HH:mm"
                            )
                          ) +
                            " - " +
                            _vm._s(
                              _vm.momentFormat(
                                _vm.selectedCell.EndTime,
                                "HH:mm"
                              )
                            )
                        )
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "b-row",
                { staticClass: "mt-1" },
                [
                  _c("feather-icon", {
                    staticClass: "mr-1",
                    attrs: { icon: "MapPinIcon" }
                  }),
                  _c("del", [_vm._v(_vm._s(_vm.selectedCell.practice_name))])
                ],
                1
              ),
              _vm.selectedCell.user
                ? _c(
                    "b-row",
                    { staticClass: "mt-1" },
                    [
                      _c("feather-icon", {
                        staticClass: "mr-1",
                        attrs: { icon: "UsersIcon" }
                      }),
                      _c("del", [
                        _vm._v(
                          _vm._s(
                            _vm.selectedCell.user.first_name +
                              " " +
                              _vm.selectedCell.user.last_name
                          )
                        )
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _vm.selectedCell.State === "pending"
                ? _c(
                    "b-row",
                    {
                      staticClass: "mt-1 applications",
                      on: {
                        click: function($event) {
                          return _vm.viewApplications()
                        }
                      }
                    },
                    [
                      _c(
                        "spn",
                        { staticClass: "applicationIcon" },
                        [
                          _c("feather-icon", { attrs: { icon: "UsersIcon" } }),
                          _c("span", {}, [_vm._v("2")])
                        ],
                        1
                      ),
                      _c("span", { staticClass: "applicationText" }, [
                        _vm._v("View Applications")
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.selectedCell.user
                ? _c(
                    "b-row",
                    { staticClass: "mt-1" },
                    [
                      _c("feather-icon", {
                        staticClass: "mr-1",
                        attrs: { icon: "UsersIcon" }
                      }),
                      _vm._l(_vm.selectedCell.vacant_job_roles, function(
                        jobRole
                      ) {
                        return _c(
                          "span",
                          { key: jobRole.id, attrs: { index: jobRole.id } },
                          [
                            _c("del", [
                              _c(
                                "span",
                                { staticStyle: { "margin-right": "5px" } },
                                [_vm._v(_vm._s(jobRole.job_role.name) + ",")]
                              )
                            ])
                          ]
                        )
                      })
                    ],
                    2
                  )
                : _vm._e(),
              _vm.selectedCell.applications_count !== 0
                ? _c("b-row", { staticClass: "mt-1 applications" }, [
                    _c(
                      "span",
                      {
                        staticClass: "applicationIcon",
                        staticStyle: {
                          "margin-bottom": "2px",
                          "background-color": "black",
                          padding: "2px",
                          width: "35px",
                          "border-radius": "8px"
                        }
                      },
                      [
                        _c("feather-icon", {
                          staticClass: "userIcon",
                          attrs: { icon: "UsersIcon" }
                        }),
                        _c("del", [
                          _c(
                            "span",
                            { staticStyle: { "padding-left": "5px" } },
                            [
                              _vm._v(
                                _vm._s(_vm.selectedCell.applications_count)
                              )
                            ]
                          )
                        ])
                      ],
                      1
                    ),
                    _c(
                      "span",
                      {
                        staticClass: "applicationText",
                        on: {
                          click: function($event) {
                            return _vm.editEvent()
                          }
                        }
                      },
                      [_vm._v("View Applications")]
                    )
                  ])
                : _vm._e()
            ],
            1
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }