<template>

  <b-sidebar
      id="add-new-user-sidebar"
      :visible="isEditShiftSidebarActive"
      backdrop
      bg-variant="white"
      no-header
      right
      shadow
      sidebar-class="sidebar-lg"
      @change="(val) => $emit('update:is-edit-shift-sidebar-active', val)"

  >
    <template #default="{ hide }">
      <b-overlay
          :show="loading"
          rounded="sm"
      >
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            Edit Shift
          </h5>
          <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
          />

        </div>

        <div class="p-1">
          <b-tabs>
            <b-tab active title="Shift Details">
              <validation-observer ref="editShiftForm">
                <b-form @submit.prevent>
                  <div class="p-1">
                    <b-row v-if="is_vacant_shift === 'true'">
                      <b-col cols="12" md="6">
                        <b-form-group>
                          <label>Professions</label>
                          <v-select
                              v-model="selectedVacantRole"
                              :options="roleList"
                              :reduce="roleList => roleList.id"
                              label="text"
                              multiple
                              placeholder="Select Role"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" md="6">
                        <b-form-group>
                          <label>Role (Optional)</label>
                          <v-select
                              v-model="selectedRoles"
                              :options="RoleLists"
                              :reduce="(RoleLists) => RoleLists.value"
                              label="text"
                              multiple
                              placeholder="Select Professions"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row v-else>
                      <b-col cols="12" md="6">
                        <b-form-group>
                          <label>Professions</label>
                          <v-select
                              v-model="selectedRoleForEdit"
                              :options="projectResourceDataSource"
                              label="text"
                              placeholder="Select Profession"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" md="6">
                        <b-form-group>
                          <label>Role (Optional)</label>
                          <v-select
                              v-model="selectedRoles"
                              :options="RoleLists"
                              :reduce="(RoleLists) => RoleLists.value"
                              label="text"
                              multiple
                              placeholder="Select Role"
                          />
                        </b-form-group>
                      </b-col>

                    </b-row>

                    <!--                    date and time section-->
                    <div class="">
                      <b-row>
                        <b-col cols="12" md="12">
                          <label>Date</label>

                          <validation-provider
                              #default="{ errors }"
                              name="Date"
                              rules="required"
                          >
                            <b-form-group>
                              <b-form-datepicker
                                  id="date"
                                  v-model="selectedDateForEdit"
                                  :date-format-options="{month: 'numeric',day: 'numeric', year: 'numeric' }"
                                  :min="min"
                                  class="form-control"
                                  locale="en-UK"
                                  placeholder="Selects"
                                  start-weekday="1"
                                  trim/>
                              <!-- <flat-pickr
                                  v-model="selectedDateForEdit"
                                  :config="{ enableTime: false, noCalendar: false, dateFormat: 'Y-m-d',locale: {
                        firstDayOfWeek: 1
                    }}"
                                  class="form-control"
                              /> -->
                            </b-form-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-col>
                        <b-col cols="12" md="4">
                          <b-form-group>
                          <label>Start Time</label>

                          
                            <validation-provider
                                #default="{ errors }"
                                name="Start Time"
                                rules="required"
                            >
                            <b-form-group>
                              <b-input-group class="input-group-merge">
                                 <b-input-group-prepend is-text>
                              <feather-icon icon="ClockIcon" />
                            </b-input-group-prepend>
                              <flat-pickr
                                  v-model="selectedStartTime"
                                  :config="{ enableTime: true, noCalendar: true, dateFormat: 'H:i:ss', }"
                                  class="form-control"
                              />
                              </b-input-group>
                            </b-form-group>
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <b-col cols="12" md="4">
                           <b-form-group>
                          <label>End Time</label>
                          <validation-provider
                              #default="{ errors }"
                              name="End Time"
                              rules="required"
                          >
                            <b-form-group>
                              <b-input-group class="input-group-merge">
                                <b-input-group-prepend is-text>
                              <feather-icon icon="ClockIcon" />
                            </b-input-group-prepend>
                              <flat-pickr
                                  v-model="selectedEndTime"
                                  :config="{ enableTime: true, noCalendar: true, dateFormat: 'H:i:ss',}"
                                  class="form-control"
                              />
                              </b-input-group>
                            </b-form-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                           </b-form-group>
                        </b-col>
                        <b-col cols="12" md="4">
                                                     <b-form-group
                          label="Break Minutes"
                          label-for="login-minutes"
                      >
                        <validation-provider
                            #default="{ errors }"
                            name="Break Minutes"
                            rules="numeric"
                        >
                          <b-form-input
                              id="login-minutes"
                              v-model="formEdit.break_minutes"
                              name="login-minutes"
                              autocomplete="off"
                              type="number"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                        </b-col>
                      </b-row>
                    </div>

                    <div>
                      <b-row
                          v-if="selectedServiceForEdit && selectedServiceTypeforEdit ==='clinical' && servicesForEdit.length !==0"
                          class="">
                        <b-col md="12">
                          <b-form-group>
                             <label> Shift Delivery (Optional)</label>
                            <validation-provider
                                #default="{ errors }"
                                name="Shift Type"
                                rules="required"
                            >
                              <v-select
                                  v-model="selectedShiftTypeForEdit"
                                  :options="shiftTypesForEdit"
                                  label="name"
                                  placeholder="Select Shift Type"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </div>


                                        <!--                    shift staff rate section -->
                    <div class="">
                      <h6 class="mb-2">Applicable Hourly Rate for salaried staff</h6>
                      <b-row class="mb-1">
                        <b-col cols="3">
                          <b-form-checkbox
                              v-model="formEdit.salary_staff_rate_type"
                              value="standard"

                          >
                            Standard
                          </b-form-checkbox>
                        </b-col>
                        <b-col cols="3">
                          <b-form-checkbox
                              v-model="formEdit.salary_staff_rate_type"
                              value="enhanced"

                          >
                            Enhanced
                          </b-form-checkbox>
                        </b-col>
                      </b-row>

                      <b-row>
                        <b-col>
                          <validation-provider
                              #default="{ errors }"
                              name="Hourly rate"
                              rules="numeric"
                          >
                            <b-form-input
                                id="stand"
                                v-model="formEdit.salary_staff_rate"
                                class="float-right mb-2"
                                name="login-email"
                                placeholder="Enter hourly rate"

                                type="number"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-col>
                      </b-row>

                      <div v-if="visible_locums === true">
                        <h6 class="mb-2">Applicable Hourly Rate locums</h6>
                        <validation-provider
                            #default="{ errors }"
                            name="Hourly rate"
                            rules="numeric"
                        >
                          <b-form-input
                              id="rate"
                              v-model="formEdit.locum_rate"
                              class="float-right mb-2"
                              name="login-email"
                              placeholder="Enter hourly rate"

                              type="number"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </div>

                    </div>





                    <div v-if="is_vacant_shift !== 'true'" class="" hidden = true >
                      <b-row>
                        <b-col>
                          <b-form-group>
                            <label>Healthcare Professional</label>
                            <v-select

                                v-model="selectedHealthcare_professionalForEdit"
                                :options="employeeDataSource.filter(x => x.groupId === selectedRoleForEdit.id)"
                                label="text"
                                placeholder="Select Healthcare Professional"
                            />
                          </b-form-group>
                        </b-col>
                      </b-row>

                    </div>


                    <div class="">
                      <b-form-checkbox
                          v-model="is_vacant_shift"
                          value="true"
                      >
                        Vacant Shift
                      </b-form-checkbox>
                    </div>

                    <!--                    service section-->
                    <div class="mt-2">
                      <b-row class="mt-2">
                        <b-col md="12">
                          <h5 class="mb-2 cursor-pointer " @click="serviceTypeHideForEditFunction()">
                            {{ serviceTypeHideForEdit === true ? '-' : '+' }} More Shift (Options)</h5>
                        </b-col>
                        <b-col md="12">
                          <h5 v-if="serviceTypeHideForEdit" class="mb-2">Activity Option</h5>
                        </b-col>

                        <b-col v-if="serviceTypeHideForEdit" md="6">
                          <b-form-checkbox
                              v-model="selectedServiceTypeforEdit"
                              class="float-left"
                              name="selectedServiceTyper"
                              value="clinical"
                          >
                            Clinical
                          </b-form-checkbox>
                          <b-form-checkbox
                              v-model="selectedServiceTypeforEdit"
                              class="float-right"
                              name="selectedServiceType"
                              value="non-clinical"
                          >
                            Non-clinical
                          </b-form-checkbox>


                        </b-col>
                      </b-row>
                      <b-row v-if="serviceTypeHideForEdit" class="">
                        <b-col md="12" class="mt-1">
                          <b-form-group>
                            <validation-provider
                                #default="{ errors }"
                                name="Service"
                                rules="required"
                            >
                              <span v-if="selectedServiceTypeforEdit == null">Please select service type</span>
                              <v-select
                                  v-model="selectedServiceForEdit"
                                  :options="servicesForEdit"
                                  label="name"
                                  placeholder="Select Service"
                              >
                              <span slot="no-options">
                                Please add services first
                              </span>
                              </v-select>
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row class="">
                                            <!--                    Note section-->
                      <b-col md="12" v-if="serviceTypeHideForEdit">
                          <label>Notes (optional)</label>
                      <b-form-textarea
                          id="textarea-default"
                          v-model="formEdit.note"
                          placeholder="Note"
                          rows="3"
                      />
                      </b-col>

                      </b-row>
                      <b-row md="12" v-if="serviceTypeHideForEdit" class="mt-1">
                        <b-col>
                          <b-form-checkbox
                               v-model="selectedCoveid"
                               value=""
                           >
                             COVID-19 Shift
                        </b-form-checkbox>
                        </b-col>
                      </b-row>
                    </div>




                    <!--        <b-form-checkbox-->
                    <!--            v-model="selected"-->
                    <!--            value="A"-->
                    <!--        >-->
                    <!--          COVID-19 Shift-->
                    <!--        </b-form-checkbox>-->



                    <!-- <div class="mt-1">
                      <h5>+ Add Break</h5>
                      <b-form-group
                          label="Break Minutes"
                          label-for="login-email"
                      >
                        <validation-provider
                            #default="{ errors }"
                            name="Break Minutes"
                            rules="numeric"
                        >
                          <b-form-input
                              id="login-email"
                              v-model="formEdit.break_minutes"
                              name="login-email"
                              type="number"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </div> -->

                    <!--                    shift visible to section -->
                    <div class="mt-1">
                      <h5 class="mb-2 text-bold">Shift is visible to</h5>
                      <b-row>
                        <b-col cols="12" md="4">
                          <b-form-checkbox
                              v-model="visible_locums"
                              :value="false"
                          >
                            Salaried Staff
                          </b-form-checkbox>


                        </b-col>
                        <b-col cols="12" md="8">
                          <b-form-checkbox
                              v-model="visible_locums"
                              :value="true"

                          >
                            Salaried Staff and Locums
                          </b-form-checkbox>
                        </b-col>

                      </b-row>
                    </div>


                    <!--                    rate calculations section-->
                    <div style="margin-top: 15px">
                      <b-alert
                          show
                          variant="primary"
                      >
                        <div class="alert-body">
                          <b-row>
                            <b-col class="text-bold text-black-50" cols="4">
                              <h5>Total Hours:
                                <span v-if="selectedEndTime&&selectedStartTime">
                            {{ totalHours }}

                          </span>
                                <span v-else>0</span>
                              </h5>
                            </b-col>
                            <b-col class="text-bold text-black-50" cols="8"><h5>Total Shift Cost:

                              <span>
                          <span>£ {{ salariedStaffCost }}</span><span
                                  v-if="visible_locums === 'true' ">/ £{{ locumCost }}</span>
                          </span>
                            </h5></b-col>


                          </b-row>
                        </div>
                      </b-alert>


                    </div>


                    <!--                    submit button section-->
                    <div class="flex mt-1">
                      <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          type="submit"
                          variant="primary"
                          @click="editShift()"
                      >
                        Save
                      </b-button>
                      <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="white"
                          @click="isEditShiftSidebarActive = false"
                      >
                        Cancel
                      </b-button>
                    </div>
                  </div>
                </b-form>
              </validation-observer>
            </b-tab>

            <!--            application tab-->
            <!-- <b-tab v-if="showApplication" active title="Applications"> -->
              <b-tab title="Applications">
              <b-overlay
                  :show="tableLoading"
                  rounded="sm"
              >

                <b-table
                    ref="tableApplication"
                    :current-page="currentPage"
                    :fields="fields"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    :items="getUserApplications"
                    :per-page="pagination.perPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :sort-direction="sortDirection"
                    class="mobile_table_css"
                    hover
                    responsive
                >


                  <template #cell(user)="data">
                    <ul class="list-inline mb-0">
                      <li class="list-inline-item">
                        <b-avatar
                            :src="getUserImage(data.item.user_id)" class="pull-up "/>
                      </li>
                      <li class="list-inline-item"> {{ data.item.user }}</li>
                      <b-badge v-if="data.item.is_locum" class="ml-1" style="padding:8px" variant="light-info">
                        <span class="text-dark font-weight-700"> L </span>
                      </b-badge>
                    </ul>


                  </template>
                  <template #cell(action)="data">
                    <div>

                      <b-button
                          v-b-tooltip.hover.top="'Accept'"
                          class="btn-icon bg-white text-primary "
                          size="23"

                          variant="outline-white"
                          @click="acceptUserApplication(data.item.id)"
                      >
                        <feather-icon class="text-primary" icon="CheckSquareIcon" size="18"/>
                      </b-button>


                      <b-button
                          v-b-tooltip.hover.top="'Reject'"
                          class="btn-icon bg-white text-danger ml-1"
                          size="23"

                          variant="outline-white"
                          @click="rejectUserApplication(data.item)"
                      >
                        <feather-icon class="text-danger" icon="XSquareIcon" size="18" variant="outline-danger"/>
                      </b-button>

                    </div>

                  </template>


                </b-table>
                <b-col v-if="noDataTable === 0" class="text-center" cols="12">
                  <span>No data for preview</span>
                </b-col>
              </b-overlay>
            </b-tab>

            <!--            swap tab-->
            <b-tab v-if="swapableType" title="Swap request">
              <div>
                  <b-card v-for="swapApplication in swapApplications" :key="swapApplication.id">
                    <b-row>
                      <b-col cols="12" md="8">
                        <ul class="list-inline mb-0">
                          <li class="list-inline-item">
                            <b-avatar
                                :src="getUserImage(swapApplication.shift_user_id)" class="pull-up "/>
                          </li>
                          <li class="list-inline-item ml-1"> {{ swapApplication.shift_user }}</li>
                        </ul>
                      </b-col>
                      <b-col cols="12" md="1">
                        <b-button
                            v-b-tooltip.hover.top="'Accept'"
                            class="btn-icon bg-white text-primary "
                            size="23"

                            variant="outline-white"
                            @click="acceptUserSwapRequest(swapApplication.id)"
                        >
                          <feather-icon class="text-primary" icon="CheckSquareIcon" size="18"/>
                        </b-button>
                      </b-col>
                      <b-col cols="12" md="1">
                        <b-button
                            v-b-tooltip.hover.top="'Reject'"
                            class="btn-icon bg-white text-danger ml-1"
                            size="23"

                            variant="outline-white"
                            @click="rejetUserSwapRequest(swapApplication.id)"
                        >
                          <feather-icon class="text-danger" icon="XSquareIcon" size="18" variant="outline-danger"/>
                        </b-button>
                      </b-col>
                      <b-col cols="12" md="2"></b-col>
                    </b-row>
                  </b-card>
                </div>
                <b-col v-if="noDataSwapTable === 0" class="text-center" cols="12">
                  <span>No data for preview</span>
                </b-col>
            </b-tab>
          </b-tabs>
        </div>


      </b-overlay>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardText,
  BLink,
  BListGroup,
  BListGroupItem,
  BCardTitle,
  BFormDatepicker,
  BCardBody,
  BFormGroup,
  BButton,
  BSidebar,
  BFormCheckbox,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BFormTextarea, VBToggle, VBModal, BFormInput, BOverlay, BForm, BTabs, BTab, BTable, BAvatar, BBadge, VBTooltip, BAlert

} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import shift from '@/apis/modules/shift'
import MomentMixin from '@/mixins/MomentMixin'
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import moment from 'moment'
import leave from '@/apis/modules/leave'
import SettingsAPI from '@/apis/modules/settings'
import settings from '@/apis/modules/settings'
import dashboard from '@/apis/modules/dashboard'

export default {
  name: 'editSideBar',
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BCardTitle,
    BListGroup,
    BListGroupItem,
    BCardBody,
    BFormGroup,
    BButton,
    BSidebar,
    BFormCheckbox,
    BFormTextarea,
    flatPickr,
    BFormDatepicker,
    BFormInput,
    BOverlay,
    ValidationObserver,
    ValidationProvider,
    BForm,
    BTab,
    BTabs,
    BTable,
    BAvatar,
    BBadge,
    BAlert,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,


  },
  mixins: [MomentMixin],

  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
    'b-modal': VBModal
  },
  data () {
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    const minDate = new Date(today)
    minDate.setMonth(minDate.getMonth())
    minDate.setDate(minDate.getDate())
    return {
      RoleLists: [],
      selectedRoles: [],
      selectedVacantRole: [],
      roleList: [],
      is_vacant_shift: 'false',
      totalHours: 0,
      salariedStaffCost: 0,
      locumCost: 0,
      visible_locums: '',
      noDataTable: '',
      tableLoading: false,
      min: minDate,
      noDataSwapTable: '',
      fields: [
        {
          key: 'user',
          label: 'APPLICANT'
        },
        {
          key: 'action',
          label: 'Actions'
        }


        // {
        //   key: 'action',
        //   label: '',
        // },

      ],
      items: [],
      currentPage: 1,
      pagination: {
        perPage: '',
        pageOptions: '',
        totalRows: '',
        from: '',
        to: ''
      },
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: ''
      },


      showApplication: '',
      swapableType: '',
      swapApplications: [],
      serviceTypeHideForEdit: false,
      selectedHealthcare_professionalForEdit: {},
      employeeDataSource: [],
      selectedRoleForEdit: {},
      projectResourceDataSource: [],
      loading: false,
      selectedStartTime: '',
      selectedEndTime: '',
      selectedCoveid : '',
      dataEvent: {},
      timePickerForEdit: '',
      selectedServiceTypeforEdit: '',
      selectedDateForEdit: '',
      servicesForEdit: [],
      selectedServiceForEdit: '',
      selected_rateForEdit: '',
      selected_hourly_rateForEdit: '',
      shiftTypesForEdit: [],
      selectedShiftTypeForEdit: '',
      formEdit: {
        hourly_rate: '',
        start: '',
        end: '',
        note: '',
        break_minutes: '',
        service_id: '',
        assignee_id: '',
        rate: '',
        salary_staff_rate_type: '',
        salary_staff_rate: null,
        locum_rate: null,
        role_id: undefined,
        is_covid_shift:''
      }
    }
  },
  model: {
    prop: 'isEditShiftSidebarActive',
    event: 'update:is-edit-shift-sidebar-active'
  },
  props: {
    isEditShiftSidebarActive: {
      type: Boolean,
      required: true
    },
    cellData: {
      type: Object,
      required: true
    }
  },
  watch: {
    selectedVacantRole (val) {
      this.formEdit.salary_staff_rate = 0
      this.formEdit.locum_rate = 0
      // this.isUserHaveLeave()
      if (val.length === 1) {
        this.getJobRoleList(val[0])
      } else {
        this.formEdit.salary_staff_rate = 0
        this.formEdit.locum_rate = 0
      }
      this.filterJobRoles(val)
    },

    selectedRoleForEdit (val) {
      this.formEdit.salary_staff_rate = 0
      this.formEdit.locum_rate = 0
      // this.isUserHaveLeave()
      if (val) {
        this.getJobRoleList(val.id)
      } else {
        this.formEdit.salary_staff_rate = 0
        this.formEdit.locum_rate = 0
      }
      this.getRole(val)
    },


    async cellData () {
      this.dataEvent = await this.cellData
      await this.setData()
      await this.getServices()
      await this.getUserData()
      this.dataEvent.event.user === null ? this.showApplication = true : this.showApplication = false
      this.$refs.tableApplication.refresh()

    },
    async 'isEditShiftSidebarActive' (val) {
      if (val) {
        await this.getAllJobRole()
      } else {
        await this.dataClear()

      }
    },
    // 'selectedRoleForEdit'(){
    //   // this.selectedHealthcare_professionalForEdit = undefined
    //
    // },

    async 'is_vacant_shift' (val) {
      if (val === 'true') {
        await this.getAllJobRole()
        this.selectedHealthcare_professionalForEdit = null
      } else {
        await this.changeAssignedShift()
      }

    },
    selectedServiceTypeforEdit () {
      if (this.selectedServiceTypeforEdit === 'clinical') {
        this.getServices('clinical')
        this.getShiftTypesForEdit()
      } else {
        this.getServices('non-clinical')
      }

    },
    'formEdit.salary_staff_rate' (newValue) {
      // this.salariedStaffCost = this.totalShiftCost(this.selectedStartTime, this.selectedEndTime,  newValue)
      this.totalHours = this.timeDifferentHours(this.selectedStartTime, this.selectedEndTime, this.formEdit.break_minutes)
      this.salariedStaffCost = this.totalShiftCost(this.totalHours, newValue)
    },
    'formEdit.locum_rate' (newValue) {
      // this.locumCost = this.totalShiftCost(this.selectedStartTime, this.selectedEndTime,  newValue)
      this.totalHours = this.timeDifferentHours(this.selectedStartTime, this.selectedEndTime, this.formEdit.break_minutes)
      this.locumCost = this.totalShiftCost(this.totalHours, newValue)
    },

    selectedStartTime () {
      // this.isUserHaveLeave()
      this.totalHours = this.timeDifferentHours(this.selectedStartTime, this.selectedEndTime, this.formEdit.break_minutes)
      this.salariedStaffCost = this.totalShiftCost(this.totalHours, this.formEdit.salary_staff_rate)

    },
    selectedEndTime () {
      // this.isUserHaveLeave()
      this.totalHours = this.timeDifferentHours(this.selectedStartTime, this.selectedEndTime, this.formEdit.break_minutes)
      this.salariedStaffCost = this.totalShiftCost(this.totalHours, this.formEdit.salary_staff_rate)


    },
    'formEdit.break_minutes' () {
      // this.isUserHaveLeave()
      this.totalHours = this.timeDifferentHours(this.selectedStartTime, this.selectedEndTime, this.formEdit.break_minutes)
      this.salariedStaffCost = this.totalShiftCost(this.totalHours, this.formEdit.salary_staff_rate)
    }
  },
  methods: {
    //get swap requset
    async getSwapApplications (response) {
      // const response = await dashboard.getSwapApplications()
      // this.shiftApplications = response.data.data
      this.swapApplications = response.map((x) => ({
        id: x.id,
        shift: x.shift,
        shift_start_date: this.momentFormat(x.shift.start, 'DD-MM-YYYY'),
        shift_start_time: this.momentFormat(x.shift.start, 'HH:mm'),
        shifte_end_time: this.momentFormat(x.shift.end, 'HH:mm'),
        shift_user: x.shift.assignee.first_name + ' ' + x.shift.assignee.last_name,
        shift_user_id: x.shift.assignee.id
      }))
      this.noDataSwapTable = response.length
    },

    //accept swap application
    async acceptUserSwapRequest (id) {
      try {
        await dashboard.acceptApplicationISwap(id)
        this.showSuccessMessage('Invitation accepted successfully')
        this.$emit('editShift')
      } catch (error) {
        this.convertAndNotifyError(error)
      }
    },

    async rejetUserSwapRequest (id) {
      try {
        await dashboard.rejectApplicationSwap(id)
        this.showSuccessMessage('Invitation rejected successfully')
        this.$emit('editShift')
      } catch (error) {
        this.convertAndNotifyError(error)
      }
    },

    async getRole (val) {
      try {
        let allRoles = []
        const Response = await settings.getRolesWithoutPagination()
        allRoles = Response.data.data.map((x) => ({
          text: x.name,
          value: x.id,
          profession: x.professions
        }))
        let selectedRoleIDS = []
        allRoles.map((x) => {
          x.profession.map((y) => {
            if (val.id === y)
              selectedRoleIDS.push(x.value)
          })
          this.RoleLists = selectedRoleIDS.map((x) => {
            return allRoles.find(e => e.value === x)
          })
        })

      } catch (e) {

      }
    },
    async filterJobRoles (val, id = null) {
      try {
        this.loading = true
        let allRoles = []
        const Response = await settings.getRolesWithoutPagination()
        allRoles = Response.data.data.map((x) => ({
          text: x.name,
          value: x.id,
          profession: x.professions
        }))

        let selectedRoleIDS = []
        allRoles.map((x) => {
          x.profession.map((y) => {
            val.map((k) => {
              if (k === y)
                selectedRoleIDS.push(x.value)
            })
          })
        })

        this.RoleLists = selectedRoleIDS.map((x) => {
          return allRoles.find(e => e.value === x)
        })

        this.RoleLists = Array.from(new Set(this.RoleLists.map(a => a.value)))
            .map(value => {
              return this.RoleLists.find(a => a.value === value)
            })
        this.loading = false

      } catch (e) {

      }
    },

    // data clear function
    async dataClear () {
      this.selectedHealthcare_professionalForEdit = null
      this.selectedVacantRole = []
    },
    // Application View
    async viewUserApplication (id) {
      try {
      } catch (error) {
        this.convertAndNotifyError(error)
      }

    },

    // get All job role for vacant shift
    async getAllJobRole () {
      try {
        const Response = await SettingsAPI.getJobRoleList()
        this.roleList = Response.data.data.map((x) => ({
              id: x.id,
              text: x.name,
              job_role_id: x.id,
              standard_rate_p_h: '',
              enhanced_rate_p_h: '',
              locum_rate_p_h: ''
            })
        )
        if (this.dataEvent.event.vacant_job_roles !== null) {
          this.selectedVacantRole = await this.dataEvent.event.vacant_job_roles.map(x => x.job_role.id)
        }

      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },

    async getJobRoleList (id) {
      try {
        this.loading = true
        const Response = await SettingsAPI.getJobRoleList()
        const ResponseWithRate = await SettingsAPI.jobRoleAssignedList()

        const job_role_with_rate = ResponseWithRate.data.data.map((x) => ({
          id: x.id,
          job_role_id: x.pivot.job_role_id,
          text: x.name,
          standard_rate_p_h: x.pivot.standard_rate_p_h,
          enhanced_rate_p_h: x.pivot.enhanced_rate_p_h,
          locum_rate_p_h: x.pivot.locum_rate_p_h
        }))

        const job_role_without_rate = Response.data.data.map((x) => ({
          id: x.id,
          text: x.name,
          job_role_id: x.id,
          standard_rate_p_h: '',
          enhanced_rate_p_h: '',
          locum_rate_p_h: ''
        }))

        const allJobRoleArray = job_role_with_rate.concat(
            job_role_without_rate
        )
        // const allJobRoleArray= [...job_role_with_rate, ...job_role_without_rate]
        this.roleList = _.uniqBy(allJobRoleArray, 'id')

        const role = []
        this.roleList.forEach(function (x) {
          if (x.id == id) {
            role.push(x)
          }
        })

        if (role.length == 0) {
          this.formEdit.salary_staff_rate = 0
          this.formEdit.locum_rate = 0
          this.enhanced_rate_p_h = ''
          this.standard_rate_p_h = ''
          this.locum_rate_p_h = ''
        }

        this.loading = false
        this.locum_rate_p_h = role[0].locum_rate_p_h
        this.standard_rate_p_h = role[0].standard_rate_p_h
        this.enhanced_rate_p_h = role[0].enhanced_rate_p_h
        this.formEdit.locum_rate = role[0].locum_rate_p_h
            ? role[0].locum_rate_p_h
            : 0
        this.formEdit.salary_staff_rate = role[0].standard_rate_p_h
            ? role[0].standard_rate_p_h
            : 0
      } catch (error) {
        this.convertAndNotifyError(error)
        this.loading = false
      }
    },


    // Accept the Application in user
    // async acceptUserApplication (id) {
    //   try {
    //     await shift.acceptApplicationInvitation(id)
    //     this.showSuccessMessage('Application Accepted successfully')
    //     this.$refs.tableSwapApplication.refresh()
    //     this.$emit('editShift')
    //   } catch (error) {
       
    //   }
    // },

    async acceptUserApplication(id){
      this.tableLoading = true
      this.$swal({
        title: 'Are you sure you want to accept this application?',
        imageUrl: require('@/assets/images/icons/publish.png'),
        imageWidth: 80,
        imageHeight: 80,
        text: '',
        showCancelButton: true,
        confirmButtonText: 'Yes, approve',
        cancelButtonText: 'No, go back',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
        }).then(async result => {
          if(result.value){
            try{
              this.tableLoading = true
              await shift.acceptApplicationInvitation(id)
              this.showSuccessMessage('Application Accepted successfully')
               this.$refs.tableApplication.refresh()
              this.tableLoading = false
              this.$emit('editShift')
            }catch(error){
               this.$refs.tableApplication.refresh()
              this.tableLoading = false
              this.showSuccessMessage('Application Accepted successfully')
            
            }
          }
        })
      this.tableLoading = false
    },




    //Reject the Application in user
    // async rejectUserApplication (item) {
    //   try {
    //     const id = item.id
    //     const user_id = item.user_id
    //     await shift.rejectApplicationInvitation(id, user_id)
    //     this.showSuccessMessage('Invitation rejected successfully')
    //     this.$refs.tableSwapApplication.refresh()
    //     this.$emit('editShift')
    //   } catch (error) {
    //   }
    // },


    async rejectUserApplication(item){
      this.tableLoading = true
      this.$swal({
        title: 'Are you sure you want to delete this application?',
        text: '',
        imageUrl: require('@/assets/images/icons/publish.png'),
          imageWidth: 80,
          imageHeight: 80,
        showCancelButton: true,
        confirmButtonText: 'Yes, delete',
        cancelButtonText: 'No, go back',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
        }).then(async result => {
           if (result.value) {
              try{
                const id = item.id
                const user_id = item.user_id
                this.tableLoading = true
                await shift.rejectApplicationInvitation(id, user_id)
                this.showSuccessMessage('Invitation rejected successfully')
                 this.$refs.tableApplication.refresh()
                this.tableLoading = false
                this.$emit('editShift')
              }catch(error){
                 this.$refs.tableApplication.refresh()
                this.tableLoading = false
                this.showSuccessMessage('Invitation rejected successfully')
            
              }
           }
        })
      this.tableLoading = false
    },

    // get The Applications of staff Apply
    async getUserApplications () {
      try {
        this.tableLoading = true
        const Response = await shift.getApplicationsForVacantShifts(this.dataEvent.event.Id)
        this.noDataTable = Response.data.data.length

        let ApplicationArray = Response.data.data.map((x) => ({
          id: x.id,
          user: x.user === null ? 'N/A' : `${x.user.first_name}  ${x.user.last_name}`,
          is_locum: x.is_locum,
          user_id: x.user_id
        }))
        this.tableLoading = false
        return ApplicationArray
      } catch (error) {
        this.convertAndNotifyError(error)
        this.tableLoading = false
      }
    },
    // Hide tha Application Tab menu
    serviceTypeHideForEditFunction () {
      this.serviceTypeHideForEdit = !this.serviceTypeHideForEdit

    },
    // get Shift Type
    async getShiftTypesForEdit () {
      try {
        this.loading = true
        const serviceResponse = await shift.getShiftTypesInPractice()

        this.shiftTypesForEdit = serviceResponse.data.data.map((x) => ({
          name: x.name,
          id: x.id
        }))
        this.loading = false

      } catch (error) {
        this.convertAndNotifyError(error)
        this.loading = false
      }
    },

    //select the user changes vacant to assigned
    async changeAssignedShift () {
      this.loading = true
      const response = await shift.userList()
      // const jobRoles = response.data.data[0].job_roles[0]
      const users = response.data.data.filter(user => user.job_roles.length !== 0)

      let groupArray = users.reduce(function (filtered, option) {
        option.job_roles.forEach((m) => {
          var obj = {
            text: m.name,
            id: m.id,
            color: '#00C49A',
            isGroup: true
          }
          filtered.push(obj)
        })
        filtered = [...new Map(filtered.map(item => [item.id, item])).values()]

        return filtered
      }, [])

      let userArray = await users.map((x) => ({
        text: `${x.first_name} ${x.last_name}`,
        id: x.id,
        groupId: x.job_roles[0].id,
        image: '1'
      }))
      this.projectResourceDataSource = groupArray
      this.selectedRoleForEdit = groupArray[0]
      this.loading = false
    },
    // Get user Data for set the Job Role And User ID
    async getUserData () {
      try {
        this.loading = true
        const response = await shift.userList()
        // const jobRoles = response.data.data[0].job_roles[0]
        const users = response.data.data.filter(user => user.job_roles.length !== 0)

        let groupArray = users.reduce(function (filtered, option) {
          option.job_roles.forEach((m) => {
            var obj = {
              text: m.name,
              id: m.id,
              color: '#00C49A',
              isGroup: true
            }
            filtered.push(obj)
          })
          filtered = [...new Map(filtered.map(item => [item.id, item])).values()]

          return filtered
        }, [])

        let userArray = users.map((x) => ({
          text: `${x.first_name} ${x.last_name}`,
          id: x.id,
          groupId: x.job_roles[0].id,
          image: '1'
        }))
        this.employeeDataSource = userArray
        this.projectResourceDataSource = groupArray

        this.selectedRoleForEdit = Object(groupArray.filter(m => m.id === this.dataEvent.event.job_role.id))[0] ? Object(groupArray.filter(m => m.id === this.dataEvent.event.job_role.id))[0] : ''
        this.selectedHealthcare_professionalForEdit = Object(userArray.filter(m => m.id === this.dataEvent.event.user_id))[0]

        this.loading = false

      } catch (error) {

        this.convertAndNotifyError(error)
        this.loading = false

      }

    },

    // Edit Shift Form Submit
    async editShift () {
      if (await this.$refs.editShiftForm.validate()) {
        try {

          if (this.selectedRoles) {
            this.selectedRoles = this.selectedRoles.map((x) => {
              return x.value
            })
          }else{
            this.selectedRoles = null
          }

          this.loading = true
          if (this.selectedHealthcare_professionalForEdit !== null) {
            this.formEdit.assignee_id = this.selectedHealthcare_professionalForEdit.id
            this.formEdit.job_role_id = this.selectedRoleForEdit.id
            this.formEdit.job_role_ids = null
          } else {
            this.formEdit.assignee_id = null
            this.formEdit.job_role_id = null
            this.formEdit.job_role_ids = this.selectedVacantRole
          }
          this.formEdit.is_covid_shift = this.selectedCoveid
          this.formEdit.service_id = this.selectedServiceForEdit.id
          this.formEdit.role_id = this.selectedRoles
          this.formEdit.shift_type_id = this.selectedShiftTypeForEdit.id
          this.formEdit.start = moment(this.selectedDateForEdit + ` ` + this.selectedStartTime).utc().format('YYYY-MM-DD HH:mm:ss')
          this.formEdit.end = moment(this.selectedDateForEdit + ` ` + this.selectedEndTime).utc().format('YYYY-MM-DD HH:mm:ss')
          this.formEdit.is_visible_locums = this.visible_locums

          // this.formEdit.start=  this.selectedDateForEdit + ` `+this.selectedStartTime
          // this.formEdit.end= this.selectedDateForEdit+ ` ` + this.selectedEndTime
          let updateResponse = await shift.updateShift(this.dataEvent.event.Id, this.formEdit)
          this.loading = false
          if (updateResponse.data.error) {
            this.showErrorMessage('There is an existing shift in this time slot')
          } else {
            this.showSuccessMessage('Shift Updated Successfully')
            this.$emit('editShift')
          }


        } catch (error) {
          this.convertAndNotifyError(error)
          this.loading = false
        }
      }


    },
    // Get service for Selectors
    async getServices (filter = this.selectedServiceTypeforEdit) {
      try {

        this.loading = true
        const serviceResponse = await shift.getServicesInPractice(filter)

        this.servicesForEdit = serviceResponse.data.data.map((x) => ({
          name: x.name,
          id: x.id
        }))

        this.loading = false
      } catch (error) {
        this.convertAndNotifyError(error)
        this.loading = false
      }
    },

    // Map Data for Edit Form
    async setData () {
      this.loading = true
      this.selectedCoveid = this.dataEvent.event.is_covid_shift
      if (this.dataEvent.event.role_id) {
        this.selectedRoles = this.dataEvent.event.role_id.map((x) => ({
          text: x.label,
          value: x.value,
          profession: x.professions
        }))
      }
      if (this.dataEvent.event.receivedSwapRequests) {
        await this.getSwapApplications(this.dataEvent.event.receivedSwapRequests)
        this.swapableType = true
      } else {
        this.swapableType = false
      }
      this.selectedDateForEdit = this.momentFormat(this.dataEvent.event.StartTime, 'YYYY-MM-DD')
      this.selectedStartTime = this.momentFormat(this.dataEvent.event.StartTime, 'HH:mm')
      this.selectedEndTime = this.momentFormat(this.dataEvent.event.EndTime, 'HH:mm')
      this.formEdit.break_minutes = this.dataEvent.event.break_minutes

      this.is_vacant_shift = this.dataEvent.event.job_role === null ? 'true' : 'false',
          this.formEdit.rate = this.dataEvent.event.rate
      this.formEdit.note = this.dataEvent.event.note
      this.formEdit.locum_rate = this.dataEvent.event.locum_rate
      this.formEdit.salary_staff_rate = this.dataEvent.event.salary_staff_rate
      this.formEdit.salary_staff_rate_type = this.dataEvent.event.salary_staff_rate_type
      this.visible_locums = !!this.dataEvent.event.is_visible_locums
      this.locumCost = this.totalShiftCost(this.selectedStartTime, this.selectedEndTime, this.formEdit.locum_rate)
      this.salariedStaffCost = this.totalShiftCost(this.selectedStartTime, this.selectedEndTime, this.formEdit.salary_staff_rate)
      this.totalHours = this.timeDifferentHours(this.selectedStartTime, this.selectedEndTime, this.formEdit.break_minutes)


      if (this.dataEvent.event.service !== null) {
        this.selectedServiceTypeforEdit = this.dataEvent.event.service.type
        this.serviceTypeHideForEdit = true
        await this.getServices(this.dataEvent.event.service.type)
        this.selectedServiceForEdit = {
          name: this.dataEvent.event.service.name,
          id: this.dataEvent.event.service.id
        }
      } else {
        this.serviceTypeHideForEdit = false
      }
      if (this.dataEvent.event.shift_type !== null) {
        this.selectedShiftTypeForEdit = {
          name: this.dataEvent.event.shift_type.name,
          id: this.dataEvent.event.shift_type.id
        }
      }
      this.loading = false

    }
  },
  mounted () {

  }

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

</style>
